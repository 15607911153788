import { View, Text } from "react-native";
import React from "react";
import DashActionItem from "./DashActionItem";
import { globalStyles } from "../styles/global";

const DashActionsCard = ({ navigation }) => {
  return (
    <View style={globalStyles.dashActionCard}>
      <DashActionItem
        title="Send"
        titleDesc="Enjoy the lowest rates and fastest speeds."
        icon="send"
        navigation={navigation}
        destination={"Selector"}
        pages={[
          {
            title: "Send to Mobile",
            description: "Send money to friends and family.",
            destination: "SendMobile",
          },
          {
            title: "Pay Merchant",
            description:
              "Send money to a business for payment of goods and services.",
            destination: "PayMerchant",
          },
        ]}
      />
      <DashActionItem
        title="Receive"
        titleDesc="Enjoy the lowest rates and fastest speeds."
        icon="download"
        navigation={navigation}
        destination={"Selector"}
        pages={[
          {
            title: "Receive from Blockchain",
            description:
              "Get a blockchain address to receive Bitcoin into your wallet from an external provider.",
            destination: "ReceiveBlockchain",
          },
        ]}
      />
      <DashActionItem
        title="Withdraw"
        titleDesc="Enjoy the lowest rates and fastest speeds."
        icon="wallet"
        navigation={navigation}
        destination={"Selector"}
        pages={[
          {
            title: "Withdraw to Mobile Money",
            description:
              "Withdraw Bitcoin directly to Mpesa, Airtel Money and more.",
            destination: "WithdrawMobile",
          },
        ]}
      />
      <DashActionItem
        title="Deposit"
        titleDesc="Enjoy the lowest rates and fastest speeds."
        icon="money"
        navigation={navigation}
        destination={"Selector"}
        pages={[
          {
            title: "Deposit from Mobile Money",
            description:
              "Buy Bitcoin directly from Mpesa, Airtel Money and more.",
            destination: "DepositMobile",
          },
        ]}
      />
    </View>
  );
};

export default DashActionsCard;
