import React from "react";
import { View, Text, Platform } from "react-native";
import tw from "tailwind-react-native-classnames";
import { Image } from "react-native-elements";
import { Icon } from "@rneui/themed";
// import IconWeb from "react-native-vector-icons/dist/FontAwesome";
// import { FontAwesome } from "@expo/vector-icons";

const LoginSignupHeader = ({ title }) => {
  return (
    <View style={tw`p-5 items-center`}>
      <Image
        style={{
          width: 128,
          height: 100,
          resizeMode: "contain",
          alignSelf: "center",
          marginTop: 70,
        }}
        source={{
          uri: "https://pt-landing-images.s3.amazonaws.com/croppedlogo-pt.jpeg",
        }}
      />

      <View style={tw`mt-8 p-2 border rounded-full border-white`}>
        {/* {Platform.OS == "web" ? (
          <IconWeb name="lock" type="ionicon" color="white" size={25} />
        ) : (
          <Icon
            name="lock-closed-outline"
            type="ionicon"
            color="white"
            size={25}
          />
        )} */}
        <Icon
          name="lock-closed-outline"
          type="ionicon"
          color="white"
          size={25}
        />
        {/* <Text style={tw`text-white`}>no icon</Text> */}
      </View>

      <Text style={tw`self-start text-3xl font-bold ml-8 mt-6 mb-1 text-white`}>
        {title}
      </Text>
    </View>
  );
};

export default LoginSignupHeader;
