import { View } from "react-native";
import React, { useEffect, useState } from "react";
import { globalStyles } from "../styles/global";
import LoginSignupHeader from "../components/LoginSignupHeader";
import VerifyPhone from "../components/VerifyPhone";
import OtpInput from "../components/OtpInput";
import CompleteSignupForm from "../components/CompleteSignupForm";
import { userRegister, verifyUserPhone } from "../services";
import * as Burnt from "burnt";
import { setUser } from "../redux/navSlice";
import { useDispatch } from "react-redux";

const SignupScreen = ({ navigation }) => {
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState("");
  const [otpId, setOtpId] = useState("");
  const dispatch = useDispatch();

  useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        // Prevent default behavior of leaving the screen
        e.preventDefault();

        if (step === 0 || step === 2) {
          return navigation.dispatch(e.data.action);
        } else {
          return setStep(step - 1);
        }
      }),
    [navigation, step]
  );

  const verifyPhoneNumber = (phone) => {
    const payload = { phone };
    verifyUserPhone(payload, (response) => {
      console.log("verify user phone >> ", response);
      if (response?.success) {
        Burnt.toast({
          title: response?.msg || response?.message, // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
        setPhone(phone);
        setOtpId(response.otpId);
        return setStep(step + 1);
      }
      Burnt.toast({
        title: response?.error || response?.msg || response?.message, // required
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    });
  };

  const verifyOtp = (otp) => {
    const payload = { phone, otp, otpId };
    verifyUserPhone(payload, (response) => {
      console.log("verify user otp >> ", response);
      if (response?.status === "success") {
        Burnt.toast({
          title: response?.msg || response?.message, // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });

        return setStep(step + 1);
      }

      Burnt.toast({
        title: response?.error || response?.msg || response?.message, // required
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    });
  };

  const completeSignup = (values) => {
    const payload = { ...values };
    delete payload.confirmpin;
    payload.phone = phone;
    payload.otpId = otpId;
    console.log("user values >> ", payload);
    userRegister(payload, (response) => {
      console.log("user register >> ", response);
      if (response?.status === "success") {
        Burnt.toast({
          title: response?.msg || response?.message, // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });

        dispatch(setUser(response.data.user));
        return navigation.replace("Dashboard");
      }
      Burnt.toast({
        title: response?.error || response?.msg || response?.message, // required
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    });
  };

  return (
    <View style={globalStyles.dashboard}>
      <LoginSignupHeader title="Signup" />

      {step === 0 && <VerifyPhone handleNextStep={verifyPhoneNumber} />}
      {step === 1 && (
        <OtpInput handleNextStep={verifyOtp} providedNumber={phone} />
      )}
      {step === 2 && <CompleteSignupForm handleSignup={completeSignup} />}
    </View>
  );
};

export default SignupScreen;
