import { View, Text, FlatList } from "react-native";
import React, { useEffect, useState } from "react";
import { globalStyles } from "../styles/global";
import ContactItem from "./ContactItem";

const ContactsCard = ({ title, data, selectContact }) => {
  return (
    <View style={globalStyles.frequentsCard}>
      <View style={globalStyles.dashCardTitle}>
        <Text style={globalStyles.frequentsContactTitleText}>{title}</Text>
      </View>
      <View style={globalStyles.contactsList}>
        {data.length === 0 && (
          <Text style={globalStyles.emptyListLbl}>Fetching contacts...</Text>
        )}
        {data?.map((item) => (
          <ContactItem
            name={item?.name}
            number={item?.number}
            key={Math.random()}
            selectContact={selectContact}
          />
        ))}
      </View>
    </View>
  );
};

export default ContactsCard;
