import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { globalStyles } from "../styles/global";

const ContactItem = ({ name, number, selectContact }) => {
  return (
    <TouchableOpacity
      style={globalStyles.contactItem}
      onPress={() => selectContact({ name, number })}
    >
      <MaterialIcons name="account-circle" size={50} color={"white"} />
      <View style={globalStyles.contactItemText}>
        <Text style={globalStyles.contactItemName}>{name}</Text>
        <Text style={globalStyles.contactItemNumber}>{number}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default ContactItem;
