import { Text, View } from "react-native";
import React, { useState } from "react";
import MerchantLoginForm from "../components/MerchantLoginForm";
import LoginSignupHeader from "../components/LoginSignupHeader";
import { globalStyles } from "../styles/global";

const MerchantLoginScreen = ({ loading }) => {
  const [rememberMe, setRememberMe] = useState(false);

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <View style={globalStyles.dashboard}>
      <LoginSignupHeader title={"Merchant Login"} />

      <MerchantLoginForm
        showPin={false}
        checked={rememberMe}
        toggleCheckbox={toggleRememberMe}
        loading={loading}
      />
    </View>
  );
};

export default MerchantLoginScreen;
