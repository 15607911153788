import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { globalColors, globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";
import * as Burnt from "burnt";

const PageItem = ({ title, description, destination, navigation }) => {
  const handleNav = () => {
    if (destination === "WithdrawBank" || destination === "DepositBank")
      return Burnt.toast({
        title: "Coming soon.", // required
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    navigation.push(destination, { title, description });
  };

  return (
    <TouchableOpacity onPress={handleNav} style={globalStyles.pageItemSelector}>
      <View style={globalStyles.pageItemSelectorTextView}>
        <Text style={globalStyles.pageItemSelectorText}>{title}</Text>
        <Text style={globalStyles.pageItemSelectorDesc}>{description}</Text>
      </View>
      <View style={globalStyles.dashCardTitleIcon}>
        <MaterialIcons name="keyboard-arrow-right" size={26} color={"white"} />
      </View>
    </TouchableOpacity>
  );
};

export default PageItem;
