import { Text, View } from "react-native";
import React, { useState } from "react";
import LoginForm from "../components/LoginForm";
import LoginSignupHeader from "../components/LoginSignupHeader";
import { globalStyles } from "../styles/global";

const LoginScreen = ({ loading }) => {
  const [rememberMe, setRememberMe] = useState(false);

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <View style={globalStyles.dashboard}>
      <LoginSignupHeader title={"Login"} />

      <LoginForm
        showPin={false}
        checked={rememberMe}
        toggleCheckbox={toggleRememberMe}
        loading={loading}
      />
    </View>
  );
};

export default LoginScreen;
