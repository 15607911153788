import React from "react";
import { Linking, Text, TextInput, View } from "react-native";
import { Formik } from "formik";
import tw from "tailwind-react-native-classnames";
import { globalColors, globalStyles } from "../styles/global";
import CustomButton from "./CustomButton";
import { TouchableOpacity } from "react-native-gesture-handler";
import * as Burnt from "burnt";
import { CONFIG } from "../services/config";
import { merchantLogin } from "../services";
import { setMerchant, setToken, setUser } from "../redux/navSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";

const MerchantLoginForm = ({ showPin, checked, toggleCheckbox, loading }) => {
  const dispatch = useDispatch();
  const { merchant } = useSelector((state) => state.nav);
  const navigation = useNavigation();
  const [loginDisabled, setLoginDisabled] = React.useState(false);

  const handleLogin = (values) => {
    if (!values.phone && !merchant.phone)
      return Burnt.toast({
        title: "Enter phone", // required
        message: "Please provide phone number",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    if (!values.pin)
      return Burnt.toast({
        title: "Enter PIN", // required
        message: "Please provide PIN",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    setLoginDisabled(true);
    loading(true);
    const payload = { ...values };
    merchantLogin(payload, (response) => {
      loading(false);
      setLoginDisabled(false);
      console.log("merchant login >> ", response);
      if (response?.status === "success") {
        Burnt.toast({
          title: response?.msg, // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
        dispatch(setMerchant(response.data.merchant));
        dispatch(setUser(null));
        dispatch(setToken(response.data.token));
        navigation?.replace("Dashboard");
      }
      Burnt.toast({
        title: response?.error || response?.msg, // required
        preset: "error", // or "error", "none", "custom"
        haptic: "error", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    });
  };

  const handleUserLogin = () => {
    navigation.push("Login");
  };

  const handleMerchantSignup = () => {
    navigation.push("MerchantSignup");
  };

  const handleContactSupport = () => {
    Linking.openURL(
      `https://wa.me/${CONFIG.whatsappSupport}?text=Hello%20I'm%20experiencing%20problems%20logging%20in%20`
    )
      .then((result) => {
        Burnt.toast({
          title: "Success", // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
      })
      .catch((error) => {
        Burnt.toast({
          title: "Something went wrong", // required
          preset: "error", // or "error", "none", "custom"
          haptic: "error", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
      });
  };

  const changePhoneNo = () => {
    dispatch(setMerchant(null));
  };

  return (
    <View style={globalStyles.loginContainer}>
      <Formik
        initialValues={{ phone: merchant?.phone || "", pin: "" }}
        onSubmit={(values) => handleLogin(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View>
            {merchant?.phone ? (
              <View style={globalStyles.loginTextView}>
                <TextInput
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={merchant?.phone}
                  editable={false}
                  style={globalStyles.textInputPhone}
                />
                <CustomButton textBtn text={"Change"} onPress={changePhoneNo} />
              </View>
            ) : (
              <TextInput
                onChangeText={handleChange("phone")}
                onBlur={handleBlur("phone")}
                value={values.phone}
                style={globalStyles.textInput}
                placeholder="Phone"
                keyboardType="numeric"
                maxLength={12}
              />
            )}

            <TextInput
              secureTextEntry={!showPin}
              onChangeText={handleChange("pin")}
              onBlur={handleBlur("pin")}
              value={values.pin}
              style={globalStyles.textInput}
              placeholder="PIN"
              keyboardType="numeric"
              maxLength={4}
              autoFocus={merchant?.phone ? true : false}
            />

            <CustomButton
              disabled={loginDisabled}
              preferred
              text={"Login"}
              onPress={handleSubmit}
            />

            <View style={globalStyles.loginSupportViewAlt}>
              <Text style={globalStyles.loginAltLabel}>
                Are you an individual?
              </Text>
              <CustomButton
                textBtn
                text={"Normal Login"}
                onPress={handleUserLogin}
              />
            </View>

            <View style={globalStyles.loginAltView}>
              <Text style={globalStyles.loginAltLabel}>
                Don't have an account?
              </Text>
              <TouchableOpacity onPress={handleMerchantSignup}>
                <Text style={globalStyles.loginAltAction}>Merchant Signup</Text>
              </TouchableOpacity>
            </View>

            <View style={globalStyles.loginSupportView}>
              <Text style={globalStyles.loginAltLabel}>
                Trouble logging in?
              </Text>
              <TouchableOpacity onPress={handleContactSupport}>
                <Text style={globalStyles.loginAltAction}>Contact Support</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </Formik>
      <Text
        style={tw`self-center text-white mb-5`}
      >{`Copyright © Pesa Token ${new Date().getFullYear()}.`}</Text>
    </View>
  );
};

export default MerchantLoginForm;
