import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  merchant: null,
  balance: { balInKes: "0.00", availableBalance: "0.00" },
  rates: {},
  token: null,
  contacts: [],
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setMerchant: (state, action) => {
      state.merchant = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setRates: (state, action) => {
      state.rates = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

export const { setUser } = navSlice.actions;
export const { setMerchant } = navSlice.actions;
export const { setBalance } = navSlice.actions;
export const { setRates } = navSlice.actions;
export const { setToken } = navSlice.actions;
export const { setContacts } = navSlice.actions;

export const selectUser = (state) => state.nav.user;
export const selectMerchant = (state) => state.nav.merchant;
export const selectBalance = (state) => state.nav.balance;
export const selectRates = (state) => state.nav.rates;
export const selectToken = (state) => state.nav.token;
export const selectContacts = (state) => state.nav.contacts;

export default navSlice.reducer;
