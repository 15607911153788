import { View, Text } from "react-native";
import React, { useState } from "react";
import { globalColors, globalStyles } from "../styles/global";
import NavHeader from "../components/NavHeader";
import { QrCodeSvg, plainRenderer } from "react-native-qr-svg";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import tw from "tailwind-react-native-classnames";
import CustomButton from "../components/CustomButton";
import SelectDropdown from "react-native-select-dropdown";
import { CONFIG } from "../services/config";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Image } from "react-native-elements";

const ReceiveBlockchain = ({}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { title, description } = route.params;

  const [currency, setCurrency] = useState("Bitcoin");
  const [address, setAddress] = useState("1FfmbHfnpaZjKFvyi1okTjJJusN455paPH");

  return (
    <View style={globalStyles.dashboard}>
      <NavHeader
        navigation={navigation}
        title={title}
        description={description}
      />

      <View style={globalStyles.receiveBlockchainTitleView}>
        <Text style={globalStyles.receiveBlockchainTitle}>
          Scan or copy your {currency} address.
        </Text>

        <SelectDropdown
          data={CONFIG.supportedCurrencies}
          onSelect={(selectedItem, index) => {
            console.log(selectedItem, index);
          }}
          defaultValue={CONFIG.supportedCurrencies[0]}
          renderButton={(selectedItem, isOpened) => {
            return (
              <View style={globalStyles.receiveBlockchainDropdownButtonStyle}>
                <Text
                  style={globalStyles.receiveBlockchainDropdownButtonTxtStyle}
                >
                  Currency
                </Text>
                <MaterialIcons
                  name={isOpened ? "keyboard-arrow-up" : "keyboard-arrow-down"}
                  color={globalColors().LUMINOUS_GREEN_HEX}
                  size={28}
                />
              </View>
            );
          }}
          renderItem={(item, index, isSelected) => {
            return (
              <View
                style={{
                  ...globalStyles.dropdownItemStyle,
                  ...(isSelected && { backgroundColor: "#D2D9DF" }),
                }}
              >
                <Text style={globalStyles.dropdownItemTxtStyle}>
                  {item.title}
                </Text>
              </View>
            );
          }}
          showsVerticalScrollIndicator={false}
          dropdownStyle={globalStyles.dropdownMenuStyle}
        />
      </View>

      <View style={globalStyles.receiveBlockchainQrView}>
        <QrCodeSvg
          style={globalStyles.qr}
          value={address}
          frameSize={170}
          contentCells={5}
          content={
            <Image
              style={globalStyles.qrIcon}
              source={{
                uri: "https://pt-landing-images.s3.amazonaws.com/croppedlogo-pt.jpeg",
              }}
            />
          }
          contentStyle={globalStyles.qrBox}
          dotColor={"#ffffff"}
          backgroundColor="#000000"
        />

        <Text style={globalStyles.receiveBlockchainQrText}>
          Scan for wallet address
        </Text>
      </View>

      <View style={globalStyles.receiveBlockchainLabelView}>
        <Text style={globalStyles.receiveBlockchainLabelTitle}>
          Wallet address
        </Text>
        <View style={globalStyles.receiveBlockchainAddressView}>
          <Text style={globalStyles.receiveBlockchainAddressText}>
            {address}
          </Text>
          <Ionicons
            name="copy-outline"
            size={26}
            color={"white"}
            style={tw`mr-3`}
          />
        </View>
      </View>

      <View style={globalStyles.receiveBlockchainWarningView}>
        <Ionicons
          name="warning-outline"
          size={32}
          color={"yellow"}
          style={tw`self-center`}
        />
        <Text style={globalStyles.receiveBlockchainWarningText}>
          Send only {currency} to this address. Sending any other coins may
          result in permanent loss.
        </Text>
      </View>

      <View style={globalStyles.receiveBlockchainActionView}>
        <CustomButton preferred={true} text={"Generate New Address"} />
      </View>
    </View>
  );
};

export default ReceiveBlockchain;
