import { View, Text } from "react-native";
import * as Location from "expo-location";
import React, { useEffect, useState } from "react";
import { globalColors, globalStyles } from "../styles/global";
import InputVerificationForm from "../components/InputVerificationForm";
import InputVerificationUpload from "../components/InputVerificationUpload";
import NavHeader from "../components/NavHeader";
import { useNavigation } from "@react-navigation/native";
import { submitKyc } from "../services";
import { useSelector } from "react-redux";
import * as Burnt from "burnt";
import VerificationResult from "../components/VerificationResult";

const Verification = ({ loading }) => {
  const { user, merchant } = useSelector((state) => state.nav);
  const navigation = useNavigation();
  const [step, setStep] = useState(0);
  const [formInfo, setFormInfo] = useState(null);
  const [loco, setLoco] = useState({ latitude: "", longitude: "" });
  const [verifResult, setVerifResult] = useState(null);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted")
        return Burnt.toast({
          title: "Permissions Required", // required
          message: "Permission to access location was denied",
          preset: "error", // or "error", "none", "custom"
          haptic: "warning", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });

      let location = await Location.getCurrentPositionAsync({});
      setLoco({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
      return Burnt.toast({
        title: "Location obtained", // required
        preset: "done", // or "error", "none", "custom"
        haptic: "success", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    })();
  }, []);

  const handleContinue = (values) => {
    console.log(values);
    setFormInfo(values);
    setStep(step + 1);
  };

  const handleSubmit = (values) => {
    console.log(values);
    const payload = {
      phone: user?.phone || merchant?.phone,
      firstName: values?.firstName,
      lastName: values?.lastName,
      govId: values?.govId,
      selfie: values?.selfie,
      idFront: values?.idFront,
      idBack: values?.idBack,
      location: [loco?.latitude, loco?.longitude],
      isMerchant: merchant ? true : false,
    };

    submitKyc(payload, (response) => {
      console.log("submit kyc >> ", response);
      setVerifResult(response);
      setStep(step + 1);
      if (response?.error)
        return Burnt.toast({
          title: response.error, // required
          preset: "error", // or "error", "none", "custom"
          haptic: "warning", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });

      if (response?.msg)
        return Burnt.toast({
          title: response.msg, // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
    });
  };

  const handleCloseProcess = () => {
    navigation.replace("Dashboard");
  };

  return (
    <View style={globalStyles.dashboard}>
      <NavHeader navigation={navigation} title={`Get Verified`} />

      {step === 0 && <InputVerificationForm handleContinue={handleContinue} />}
      {step === 1 && (
        <InputVerificationUpload
          handleSubmit={handleSubmit}
          formInfo={formInfo}
          loading={loading}
        />
      )}
      {step === 2 && (
        <VerificationResult
          handleCloseProcess={handleCloseProcess}
          result={verifResult}
        />
      )}
    </View>
  );
};

export default Verification;
