import { View, Text, ScrollView } from "react-native";
import React from "react";
import { globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";
import ActivityItem from "./ActivityItem";

const ActivityCard = ({ history, rates }) => {
  return (
    <View style={globalStyles.activityCard}>
      <View style={globalStyles.dashCardTitle}>
        <Text style={globalStyles.dashCardTitleText}>Activity</Text>
        <View style={globalStyles.dashCardTitleIcon}>
          <MaterialIcons
            name="keyboard-arrow-right"
            size={26}
            color={"white"}
          />
        </View>
      </View>
      <ScrollView style={globalStyles.frequentsList}>
        {history?.length === 0 && (
          <Text style={globalStyles.emptyListLbl}>
            Your recent activity will show up here.
          </Text>
        )}
        {history?.map((item) => (
          <ActivityItem tx={item} rates={rates} key={Math.random()} />
        ))}

        {/* <FlatList
          data={history}
          keyExtractor={(item) => Math.random()}
          renderItem={({ item }) => <ActivityItem item={item} />}
        /> */}
      </ScrollView>
    </View>
  );
};

export default ActivityCard;
