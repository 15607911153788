import { View, Text } from "react-native";
import React from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { globalStyles } from "../styles/global";

const NavHeader = ({ navigation, title, description, process }) => {
  const goBack = () => {
    navigation.goBack();
  };

  const cancelProcess = () => {
    navigation.replace("Dashboard");
  };

  return (
    <View style={globalStyles.headerStyle}>
      <View style={globalStyles.navHeaderLeft}>
        <MaterialIcons
          name={process ? "clear" : "keyboard-arrow-left"}
          size={process ? 24 : 36}
          onPress={process ? cancelProcess : goBack}
          style={globalStyles.navHeaderBack}
          color={"white"}
        />

        <View style={globalStyles.navHeaderTextView}>
          <Text style={globalStyles.navHeaderTextTitle}>{title}</Text>
          <Text style={globalStyles.navHeaderTextDesc}>{description}</Text>
        </View>
      </View>
    </View>
  );
};

export default NavHeader;
