import React, { useEffect } from "react";
import * as Burnt from "burnt";
import { useNavigation } from "@react-navigation/native";
import { Linking, Platform, Text, TextInput, View } from "react-native";
import { Formik } from "formik";
import tw from "tailwind-react-native-classnames";
import { globalColors, globalStyles } from "../styles/global";
import CustomButton from "./CustomButton";
import { TouchableOpacity } from "react-native-gesture-handler";
import { CONFIG } from "../services/config";
import { userLogin } from "../services";
import { setMerchant, setToken, setUser } from "../redux/navSlice";
import { useDispatch, useSelector } from "react-redux";

const LoginForm = ({ showPin, checked, toggleCheckbox, loading }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.nav);
  const [loginDisabled, setLoginDisabled] = React.useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    // console.log("user > ", user);
  }, []);

  const handleLogin = (values) => {
    if (!values.phone && !user.phone)
      return Burnt.toast({
        title: "Enter Phone", // required
        preset: "error", // or "error", "none", "custom"
        message: "Please provide phone number!", // optional
        haptic: "error", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
        from: "bottom", // "top" or "bottom"
      });

    if (!values.pin)
      return Burnt.toast({
        title: "Enter PIN", // required
        preset: "error", // or "error", "none", "custom"
        message: "Please provide PIN", // optional
        haptic: "error", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    setLoginDisabled(true);
    loading(true);
    const payload = { ...values };
    userLogin(payload, (response) => {
      // console.log("user login >> ", response);
      loading(false);
      setLoginDisabled(false);
      if (response?.status === "success") {
        Burnt.toast({
          title: response?.msg, // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });

        dispatch(setUser(response.data.user));
        dispatch(setMerchant(null));
        dispatch(setToken(response.data.token));
        return navigation?.replace("Dashboard");
      }

      Burnt.toast({
        title: response?.error || response?.msg, // required
        preset: "error", // or "error", "none", "custom"
        haptic: "error", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    });
  };

  // const handleLogin = () => {
  //   navigation.push("Dashboard");
  // };

  const handleMerchantLogin = () => {
    navigation.push("MerchantLogin");
  };

  const handleSignup = () => {
    navigation.push("Signup");
  };

  const handleContactSupport = () => {
    Linking.openURL(
      `https://wa.me/${CONFIG.whatsappSupport}?text=Hello%20I'm%20experiencing%20problems%20logging%20in%20`
    )
      .then((result) => {
        Burnt.toast({
          title: "Success", // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
      })
      .catch((error) => {
        Burnt.toast({
          title: "Something went wrong", // required
          preset: "error", // or "error", "none", "custom"
          haptic: "error", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
        console.log("Error on contact support >> ", error);
      });
  };

  const changePhoneNo = () => {
    dispatch(setUser(null));
  };

  return (
    <View style={globalStyles.loginContainer}>
      <Formik
        initialValues={{ phone: user?.phone || "", pin: "" }}
        onSubmit={(values) => handleLogin(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View>
            {user?.phone ? (
              <View style={globalStyles.loginTextView}>
                <TextInput
                  onChangeText={handleChange("phone")}
                  onBlur={handleBlur("phone")}
                  value={user?.phone}
                  editable={false}
                  style={globalStyles.textInputPhone}
                />
                <CustomButton textBtn text={"Change"} onPress={changePhoneNo} />
              </View>
            ) : (
              <TextInput
                onChangeText={handleChange("phone")}
                onBlur={handleBlur("phone")}
                value={values.phone}
                style={globalStyles.textInput}
                placeholder="Phone"
                keyboardType="numeric"
                maxLength={12}
              />
            )}

            <TextInput
              secureTextEntry={!showPin}
              onChangeText={handleChange("pin")}
              onBlur={handleBlur("pin")}
              style={globalStyles.textInput}
              placeholder="PIN"
              keyboardType="number-pad"
              maxLength={4}
              autoFocus={user?.phone ? true : false}
            />

            <CustomButton
              disabled={loginDisabled}
              preferred
              text={"Login"}
              onPress={handleSubmit}
            />

            <View style={globalStyles.loginSupportViewAlt}>
              <Text style={globalStyles.loginAltLabel}>
                Are you a business?
              </Text>
              <CustomButton
                textBtn
                text={"Merchant Login"}
                onPress={handleMerchantLogin}
              />
            </View>

            <View style={globalStyles.loginAltView}>
              <Text style={globalStyles.loginAltLabel}>
                Don't have an account?
              </Text>
              <TouchableOpacity onPress={handleSignup}>
                <Text style={globalStyles.loginAltAction}>Signup</Text>
              </TouchableOpacity>
            </View>

            <View style={globalStyles.loginSupportView}>
              <Text style={globalStyles.loginAltLabel}>
                Trouble logging in?
              </Text>
              <TouchableOpacity onPress={handleContactSupport}>
                <Text style={globalStyles.loginAltAction}>Contact Support</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </Formik>
      <Text
        style={tw`self-center text-white mb-5`}
      >{`Copyright © Pesa Token ${new Date().getFullYear()}.`}</Text>
    </View>
  );
};

export default LoginForm;
