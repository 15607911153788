import { StyleSheet } from "react-native";

export const globalColors = (opacity) => {
  return {
    LIGHT_GREEN_HEX: `#6cf065`,
    LIGHT_GREEN_RGBA: `rgba(108,240,101,${opacity})`,
    BLACK_HEX: `#040404`,
    BLACK_RGBA: `rgba(4,4,4,${opacity})`,
    MEDIUM_GREEN_HEX: `#1f5e1c`,
    MEDIUM_GREEN_RGBA: `rgba(31,94,28,${opacity})`,
    DEEP_GREEN_HEX: `#052e05`,
    DEEP_GREEN_RGBA: `rgba(5,46,5,${opacity})`,
    LUMINOUS_GREEN_HEX: `#20971f`,
    LUMINOUS_GREEN_RGBA: `rgba(32,151,31,${opacity})`,
    CARD_GREY: `#303030`,
    DEEP_CARD_GREY: `#151515`,
    ACTIVITY_ITEM: `#101010`,
    CARD_TEXT: `#b9b9b9`,
    CARD_ITEM: `#7e7e7e`,
  };
};

export const globalStyles = StyleSheet.create({
  textInput: {
    width: "auto",
    backgroundColor: "#ffffff",
    borderWidth: 2,
    borderColor: globalColors().LUMINOUS_GREEN_HEX,
    shadowColor: globalColors(250).LUMINOUS_GREEN_RGBA,
    padding: 10,
    paddingHorizontal: 40,
    fontSize: 16,
    borderRadius: 3,
    marginBottom: 20,
  },
  textInputPhone: {
    width: "75%",
    backgroundColor: "#ffffff",
    borderWidth: 2,
    borderColor: globalColors().LUMINOUS_GREEN_HEX,
    shadowColor: globalColors(250).LUMINOUS_GREEN_RGBA,
    padding: 10,
    paddingHorizontal: 40,
    fontSize: 16,
    borderRadius: 3,
    marginBottom: 20,
  },
  registerContainer: {
    padding: 5,
    marginTop: 10,
  },
  loginContainer: {
    flex: 1,
    paddingHorizontal: 40,
    marginTop: 10,
    justifyContent: "space-between",
  },
  loginTextView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  loginBtn: {
    padding: 10,
  },
  loginAltView: {
    flexDirection: "row",
    marginTop: 10,
  },
  loginSupportView: {
    flexDirection: "row",
    marginTop: 5,
  },
  loginSupportViewAlt: {
    marginTop: 20,
  },
  loginAltLabel: {
    color: "white",
  },
  loginAltAction: {
    color: globalColors().LUMINOUS_GREEN_HEX,
    textDecorationLine: "underline",
    marginLeft: 10,
  },
  btn: {
    marginTop: 10,
    borderRadius: 3,
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: "lightgrey",
  },
  preferredBtn: {
    marginTop: 10,
    borderRadius: 3,
    paddingVertical: 10,
    paddingHorizontal: 10,
    backgroundColor: globalColors().LUMINOUS_GREEN_HEX,
  },
  textBtn: {
    marginTop: 10,
    borderRadius: 3,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  textBtnOutline: {
    color: globalColors().LUMINOUS_GREEN_HEX,
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 16,
    textAlign: "center",
  },
  btnText: {
    color: "black",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: 16,
    textAlign: "center",
  },
  dashboard: {
    flex: 1,
    backgroundColor: globalColors().BLACK_HEX,
  },
  dashItemContainer: {
    flexDirection: "row",
    paddingVertical: 12,
    marginVertical: 20,
    marginHorizontal: 30,
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 3,
    backgroundColor: globalColors(0.125).LUMINOUS_GREEN_RGBA,
  },
  dashItemText: {
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "center",
    alignSelf: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 20,
  },
  button: {
    borderRadius: 10,
    padding: 10,
    elevation: 5,
  },
  buttonClose: {
    backgroundColor: globalColors().DEEP_GREEN_HEX,
  },
  textStyle: {
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 20,
    textAlign: "center",
  },
  modalTitle: {
    marginBottom: 10,
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
  },
  headerStyle: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  navHeaderLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerAccount: { marginRight: 10 },
  navHeaderBack: {
    marginRight: 10,
    marginLeft: 10,
  },
  headerTextTitle: {
    fontSize: 15,
    fontWeight: "light",
    color: "white",
  },
  navHeaderTextView: {
    width: "80%",
  },
  navHeaderTextTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
  },
  navHeaderTextDesc: {
    fontSize: 13,
    fontWeight: "thin",
    color: "white",
  },
  headerTextName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
  },
  dashBanner: {
    flexDirection: "row",
    paddingVertical: 7,
    alignItems: "center",
  },
  balanceCard: {
    marginHorizontal: 15,
    marginTop: 10,
    padding: 10,
    backgroundColor: globalColors().DEEP_CARD_GREY,
    borderRadius: 20,
  },
  balanceTopper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  balanceTitle: {
    color: globalColors().CARD_TEXT,
    marginTop: 2,
    marginLeft: 2,
  },
  merchantNoLabel: {
    color: globalColors().CARD_TEXT,
    marginRight: 5,
  },
  merchantNoText: {
    color: "white",
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 10,
  },
  balanceBody: {
    marginVertical: 5,
    paddingRight: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  balanceBodyInner: {
    flexDirection: "row",
    alignItems: "center",
  },
  blurView: {
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: { width: 10, height: 10 },
    shadowRadius: 5,
    elevation: 5,
    borderWidth: 0.5,
    borderRadius: 20,
    paddingHorizontal: 5,
  },
  balanceAmount: {
    fontSize: 40,
    fontStyle: "normal",
    fontWeight: "bold",
    color: "white",
  },
  balanceRateItem: {
    padding: 5,
    marginTop: 5,
    backgroundColor: globalColors().CARD_GREY,
    borderRadius: 10,
    alignSelf: "flex-start",
  },
  balanceRateText: {
    paddingHorizontal: 5,
    color: "white",
  },
  dashActionCard: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 10,
    padding: 15,
  },
  dashActionItem: {
    flexDirection: "column",
    alignContent: "center",
  },
  dashActionItemIcon: {
    backgroundColor: globalColors().LUMINOUS_GREEN_HEX,
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    padding: 14,
    borderRadius: 100,
  },
  dashActionItemText: {
    color: "white",
    alignSelf: "center",
    marginTop: 10,
    fontWeight: "bold",
  },
  frequentsList: {
    padding: 5,
    overflow: "scroll",
  },
  emptyListLbl: {
    color: "white",
    padding: 20,
  },
  frequentsTxItem: {
    flexDirection: "column",
    alignContent: "center",
    padding: 10,
  },
  frequentsTxItemIcon: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    borderRadius: 100,
  },
  frequentsTxItemText: {
    color: "white",
    alignSelf: "center",
    textAlign: "center",
    marginTop: 5,
  },
  dashCardTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  dashCardTitleText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: 5,
  },
  dashCardTitleIcon: {
    alignContent: "center",
    marginRight: 10,
  },
  activityCard: {},
  activityItem: {
    padding: 10,
    marginHorizontal: 10,
    marginVertical: 4,
    backgroundColor: globalColors().ACTIVITY_ITEM,
    borderRadius: 10,
  },
  activityItemTop: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  activityItemMid: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  activityItemMidArrow: {
    alignSelf: "flex-start",
  },
  activityItemBottom: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    marginTop: 7,
    paddingTop: 5,
    borderTopColor: globalColors().CARD_GREY,
    borderTopWidth: 1,
  },
  activityItemText: {
    marginLeft: 10,
    alignSelf: "center",
    minWidth: "61%",
  },
  activityItemName: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
  activityItemTime: {
    color: globalColors().CARD_ITEM,
    fontWeight: "normal",
  },
  activityItemStatus: {
    fontWeight: "bold",
  },
  activityItemStatusView: {
    alignSelf: "flex-end",
  },
  activityItemBtc: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
  activityItemKes: {
    color: globalColors().CARD_TEXT,
    fontWeight: "normal",
  },
  notifications: {
    flex: 1,
    backgroundColor: globalColors().BLACK_HEX,
  },
  pageItemSelector: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 15,
  },
  pageItemSelectorTextView: {
    width: "80%",
  },
  pageItemSelectorText: {
    color: "white",
    fontSize: 20,
    marginLeft: 15,
  },
  pageItemSelectorDesc: {
    color: "white",
    fontSize: 12,
    marginLeft: 15,
  },
  selector: {
    flex: 1,
    backgroundColor: globalColors().BLACK_HEX,
  },
  searchBarContainer: {
    paddingHorizontal: 15,
    borderRadius: 10,
    marginHorizontal: 15,
  },
  searchBar: {
    color: "white",
  },
  enterPhoneNo: {
    flexDirection: "row",
    margin: 20,
    alignItems: "center",
  },
  enterPhoneNoText: { color: "white", fontSize: 18 },
  contactsList: {
    padding: 10,
  },
  frequentsContactTitleText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 10,
  },
  contactItem: {
    flexDirection: "row",
    marginLeft: 10,
    padding: 10,
  },
  contactItemText: {
    alignSelf: "center",
    marginLeft: 15,
  },
  contactItemName: {
    color: "white",
  },
  contactItemNumber: {
    color: "white",
  },
  verifyNumber: {
    flex: 1,
    paddingHorizontal: 30,
  },
  verifyNumberView: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    borderColor: globalColors().LUMINOUS_GREEN_HEX,
    borderRadius: 5,
    borderWidth: 2,
    padding: 5,
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  verifyNumberText: {
    padding: 5,
    paddingHorizontal: 20,
    color: "white",
    fontSize: 18,
  },
  otpContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  otpContainerText: {
    color: "white",
    marginBottom: 20,
    alignSelf: "center",
  },
  otpInputBox: {
    borderWidth: 1,
    borderColor: globalColors().LUMINOUS_GREEN_HEX,
    borderRadius: 10,
    padding: 5,
    color: "white",
    width: 50,
    height: 50,
    margin: 10,
    textAlign: "center",
    fontSize: 20,
  },
  otpAltView: {
    alignSelf: "flex-end",
    marginTop: 30,
    paddingHorizontal: 20,
    marginRight: 20,
  },
  otpAltText: {
    color: "white",
    alignSelf: "flex-end",
  },
  otpAltResend: {
    color: globalColors().LUMINOUS_GREEN_HEX,
    marginTop: 5,
    alignSelf: "flex-end",
  },
  otpAltSupport: {
    color: globalColors().LUMINOUS_GREEN_HEX,
    marginTop: 5,
    alignSelf: "flex-end",
  },
  completeSignupContainer: {
    paddingHorizontal: 40,
    alignSelf: "center",
  },
  completeSignupText: {
    color: "white",
    marginBottom: 15,
    paddingHorizontal: 5,
  },
  inputNumber: {
    flex: 1,
    padding: 10,
    paddingHorizontal: 30,
    justifyContent: "space-between",
  },
  inputNumberView: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 80,
    marginBottom: 80,
    borderColor: globalColors().CARD_GREY,
    borderWidth: 2,
    borderRadius: 5,
    padding: 7,
  },
  inputAmount: {
    flex: 1,
    padding: 10,
    paddingHorizontal: 30,
  },
  inputAmountInner: {
    flex: 1,
  },
  inputAmountView: {
    alignSelf: "center",
    marginTop: 5,
    borderColor: globalColors().CARD_GREY,
    borderWidth: 2,
    borderRadius: 5,
    padding: 7,
    width: "75%",
  },
  inputAmountInnerView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputAmountLimit: {
    color: globalColors().CARD_ITEM,
    alignSelf: "center",
    width: "70%",
    marginTop: 3,
  },
  inputAmountFee: {
    color: globalColors().CARD_ITEM,
    alignSelf: "center",
    width: "61%",
    marginTop: 3,
  },
  inputAmountLabel: {
    color: "white",
    marginLeft: 5,
    marginBottom: 5,
  },
  inputPin: {
    flex: 1,
    padding: 10,
    paddingHorizontal: 30,
    paddingBottom: 50,
  },
  inputPinInner: {
    flex: 1,
  },
  inputPinView: {
    alignSelf: "center",
    marginTop: 10,
  },
  inputNumberText: {
    padding: 5,
    color: "white",
    fontSize: 18,
    maxWidth: "70%",
  },
  inputPinText: {
    alignSelf: "center",
    borderColor: globalColors().CARD_GREY,
    borderWidth: 2,
    borderRadius: 5,
    padding: 7,
    color: globalColors().CARD_ITEM,
    fontSize: 16,
  },
  inputPinFee: {
    color: globalColors().CARD_ITEM,
    alignSelf: "center",
    marginTop: 5,
  },
  dropdownButtonStyle: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  dropdownButtonTxtStyle: {
    fontSize: 15,
    color: "white",
  },
  dropdownMenuStyle: {
    backgroundColor: "#E9ECEF",
    borderRadius: 5,
  },
  dropdownItemStyle: {
    width: "100%",
    flexDirection: "row",
    paddingHorizontal: 12,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 8,
  },
  dropdownItemTxtStyle: {
    flex: 1,
    fontSize: 18,
    fontWeight: "500",
    color: "#151E26",
  },
  inputAmountBalance: {
    marginBottom: 10,
    marginTop: 10,
    alignSelf: "center",
    width: "61%",
  },
  inputAmountBalanceView: {
    marginTop: 5,
    marginLeft: 20,
  },
  inputAmountBalanceText: {
    color: globalColors().CARD_ITEM,
    marginRight: 5,
  },
  inputAmountBalanceItem: {
    color: globalColors().CARD_TEXT,
    fontSize: 15,
  },
  inputAmountRecipient: {
    alignSelf: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  inputAmountRecipientName: {
    color: globalColors().CARD_TEXT,
    fontSize: 25,
    marginTop: 5,
    alignSelf: "center",
    textAlign: "center",
  },
  inputAmountRecipientNumber: {
    color: globalColors().CARD_TEXT,
    fontSize: 12,
  },
  receiveBlockchainTitleView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  receiveBlockchainTitle: {
    color: "white",
    fontSize: 16,
    marginLeft: 30,
  },
  receiveBlockchainDropdownButtonStyle: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
  },
  receiveBlockchainDropdownButtonTxtStyle: {
    fontSize: 15,
    color: globalColors().LUMINOUS_GREEN_HEX,
  },
  qrBox: {
    alignItems: "center",
    justifyContent: "center",
  },
  qrIcon: {
    fontSize: 20,
  },
  qr: {
    padding: 15,
  },
  receiveBlockchainQrView: {
    alignSelf: "center",
    alignItems: "center",
    marginTop: 30,
  },
  receiveBlockchainQrText: {
    alignSelf: "center",
    color: "white",
    marginTop: 20,
  },
  receiveBlockchainLabelView: {
    padding: 20,
  },
  receiveBlockchainLabelTitle: {
    color: globalColors().CARD_TEXT,
    marginLeft: 10,
  },
  receiveBlockchainAddressView: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 10,
  },
  receiveBlockchainAddressText: {
    color: "white",
  },
  receiveBlockchainWarningView: {
    alignSelf: "center",
    alignContent: "center",
    borderRadius: 10,
    borderColor: "red",
    borderWidth: 2,
    padding: 10,
    margin: 25,
  },
  receiveBlockchainWarningText: {
    color: "white",
    alignSelf: "center",
    marginTop: 10,
    textAlign: "center",
  },
  receiveBlockchainActionView: {
    padding: 30,
  },
  processResultView: {
    flex: 1,
  },
  processResultAnimationView: {
    marginTop: 150,
    width: "50%",
    height: "30%",
    alignSelf: "center",
  },
  processResultDetailsView: {
    alignSelf: "center",
  },
  processResultDetailsText: {
    color: globalColors().CARD_TEXT,
    fontWeight: "bold",
    fontSize: 18,
    margin: 20,
    textAlign: "center",
  },
  processResultDetailsAmount: {
    fontSize: 22,
    color: "white",
  },
  processResultDetailsRecipient: {
    fontSize: 22,
    color: "white",
  },
  processResultRefView: {
    backgroundColor: globalColors().CARD_GREY,
    alignSelf: "center",
    padding: 10,
    borderRadius: 20,
    marginTop: 20,
  },
  processResultRefText: {
    marginHorizontal: 10,
    color: globalColors().CARD_TEXT,
    fontWeight: "bold",
  },
  processResultActionView: {
    marginTop: 40,
    paddingHorizontal: 60,
  },
  verificationView: {
    paddingHorizontal: 40,
  },
  verificationText: {
    color: "white",
    fontSize: 16,
    marginBottom: 20,
  },
  inputVerificationName: {
    padding: 5,
    color: "white",
    marginBottom: 10,
  },
  verificationImageView: {},
  verificationImageSelect: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  verificationImage: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
  },
});
