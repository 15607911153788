import React, { useEffect, useState } from "react";
import { View, TextInput, StyleSheet, Text, Linking } from "react-native";
import CustomButton from "./CustomButton";
import tw from "tailwind-react-native-classnames";
import { globalColors, globalStyles } from "../styles/global";
import { TouchableOpacity } from "react-native-gesture-handler";
import { CONFIG } from "../services/config";
import * as Burnt from "burnt";

const OtpInput = ({ handleNextStep, providedNumber }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpNo, setOtpNo] = useState("");
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    let inputtedNumber = otp.join("");
    setOtpNo(inputtedNumber);
    if (inputtedNumber.length === 4) return setCanContinue(true);
    if (inputtedNumber.length !== 4) return setCanContinue(false);
  }, [otp]);

  const doContinue = () => {
    if (canContinue) handleNextStep(otpNo);
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next box if the current one has a value
    if (value && index < newOtp.length - 1) {
      inputs[index + 1].focus();
    }
  };

  let inputs = [];

  const handleContactSupport = () => {
    Linking.openURL(
      `https://wa.me/${CONFIG.whatsappSupport}?text=Hello%20I'm%20experiencing%20problems%20receiving%20the%20otp`
    )
      .then((result) => {
        Burnt.toast({
          title: "Success", // required
          preset: "done", // or "error", "none", "custom"
          haptic: "success", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
      })
      .catch((error) => {
        Burnt.toast({
          title: "Something went wrong", // required
          preset: "error", // or "error", "none", "custom"
          haptic: "error", // or "success", "warning", "error"
          duration: 2, // duration in seconds
          shouldDismissByDrag: true,
        });
      });
  };

  return (
    <View>
      <Text style={globalStyles.otpContainerText}>
        Enter the OTP sent to +{providedNumber}
      </Text>
      <View style={globalStyles.otpContainer}>
        {otp.map((digit, index) => (
          <TextInput
            key={index}
            style={globalStyles.otpInputBox}
            maxLength={1}
            keyboardType="numeric"
            onChangeText={(value) => handleOtpChange(value, index)}
            value={digit}
            ref={(input) => {
              inputs[index] = input;
            }}
          />
        ))}
      </View>

      <View style={tw`px-10`}>
        <CustomButton
          disabled={!canContinue}
          preferred={canContinue}
          text={"Confirm"}
          onPress={doContinue}
        />
      </View>

      <View style={globalStyles.otpAltView}>
        <Text style={globalStyles.otpAltText}>Not received?</Text>

        <TouchableOpacity>
          <Text style={globalStyles.otpAltResend}>Resend</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={handleContactSupport}>
          <Text style={globalStyles.otpAltSupport}>Contact Support</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default OtpInput;
