import { View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { globalColors, globalStyles } from "../styles/global";
import Banner from "./Banner";
import { useSelector } from "react-redux";
import { checkKycStatus } from "../services";
import * as Burnt from "burnt";

const CustomHeader = ({ navigation, route, options, back }) => {
  const { user, merchant } = useSelector((state) => state.nav);
  const [verifStatus, setVerifStatus] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [verifMessage, setVerifMessage] = useState(
    "Please verify your identity!"
  );

  // Check kyc verification status to display banner accordingly
  useEffect(() => {
    const payload = { phone: user?.phone || merchant?.phone };
    checkKycStatus(payload, (response) => {
      if (response?.length > 0) {
        setVerifStatus(response[0].status);
        setWaiting(true);
        setVerifMessage("Documents are under review.");
      }
    });
  }, [user, merchant]);

  const getSalutation = () => {
    const currentHrs = new Date().getHours();

    if (currentHrs >= 0 && currentHrs <= 11) return "Morning";
    if (currentHrs >= 12 && currentHrs <= 16) return "Afternoon";
    if (currentHrs >= 17 && currentHrs <= 23) return "Evening";
  };

  const openMenu = () => {
    // navigation.openDrawer();
  };

  const openNotifications = () => {
    navigation.push("Notifications");
  };

  const handleClickBanner = () => {
    if (waiting)
      return Burnt.toast({
        title: "Under review", // required
        preset: "done", // or "error", "none", "custom"
        message: "Your documents are being reviewed. Please contact support.", // optional
        haptic: "success", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });
    navigation.push("Verification");
  };

  return (
    <View>
      <View style={globalStyles.headerStyle}>
        <View style={globalStyles.headerLeft}>
          <MaterialIcons
            name="account-circle"
            size={38}
            onPress={openMenu}
            style={globalStyles.headerAccount}
            color={"white"}
          />
          <View>
            <Text style={globalStyles.headerTextTitle}>
              Good {getSalutation()},
            </Text>
            <Text style={globalStyles.headerTextName}>
              {user?.firstName || merchant?.repName}
            </Text>
          </View>
        </View>
        <View style={globalStyles.headerRight}>
          <MaterialIcons
            name="notifications-none"
            size={30}
            onPress={openNotifications}
            color={"white"}
          />
        </View>
      </View>
      {typeof user?.kyc === "string" ? null : typeof merchant?.kyc ===
        "string" ? null : (
        <Banner
          handleClickBanner={handleClickBanner}
          bannerStyle={globalStyles.dashBanner}
          message={verifMessage}
          waiting={waiting}
        />
      )}
    </View>
  );
};

export default CustomHeader;
