import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";
import { CONFIG } from "../services/config";
import SelectDropdown from "react-native-select-dropdown";

const BalanceCard = ({ balance, rates, merchant, currency, setCurrency }) => {
  const [bal, setBal] = useState(
    balance?.availableBalance?.toString() || "0.00"
  );

  useEffect(() => {
    switch (currency) {
      case 0: // BTC
        return setBal(balance?.availableBalance?.toString());
      case 1:
        return setBal(
          balance?.balInKes?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
            "0.00"
        );
      case 2:
        return setBal(
          balance?.balInKes?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
            "0.00"
        );
      case 3: // KES
        return setBal(
          balance?.balInKes?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
            "0.00"
        );

      default:
        break;
    }
  }, [balance, rates, currency]);

  const handleConvertBalance = (selectedItem, index) => {
    console.log(selectedItem, index);
    setCurrency(index);
    if (index === 0) return setBal(balance?.availableBalance?.toString());
    if (index === 3)
      return setBal(
        balance?.balInKes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
  };

  return (
    <View style={globalStyles.balanceCard}>
      <View style={globalStyles.balanceTopper}>
        <Text style={globalStyles.balanceTitle}>Your balance</Text>
        <View style={globalStyles.balanceTopper}>
          <Text style={globalStyles.merchantNoLabel}>Merchant No.</Text>
          <Text style={globalStyles.merchantNoText}>
            {merchant?.merchantNo || "-"}
          </Text>
        </View>
      </View>
      <View style={globalStyles.balanceBody}>
        <View style={globalStyles.balanceBodyInner}>
          <SelectDropdown
            data={CONFIG.supportedCurrencies}
            onSelect={handleConvertBalance}
            defaultValue={CONFIG.supportedCurrencies[3]}
            renderButton={(selectedItem, isOpened) => {
              return (
                <View style={globalStyles.dropdownButtonStyle}>
                  <Text style={globalStyles.dropdownButtonTxtStyle}>
                    {(selectedItem && selectedItem.title) || "Select currency"}
                  </Text>
                  <MaterialIcons
                    name={
                      isOpened ? "keyboard-arrow-up" : "keyboard-arrow-down"
                    }
                    color={"white"}
                    size={28}
                  />
                </View>
              );
            }}
            renderItem={(item, index, isSelected) => {
              return (
                <View
                  style={{
                    ...globalStyles.dropdownItemStyle,
                    ...(isSelected && { backgroundColor: "#D2D9DF" }),
                  }}
                >
                  <Text style={globalStyles.dropdownItemTxtStyle}>
                    {item.title}
                  </Text>
                </View>
              );
            }}
            showsVerticalScrollIndicator={false}
            dropdownStyle={globalStyles.dropdownMenuStyle}
          />
          <View style={globalStyles.blurView}>
            <Text style={globalStyles.balanceAmount}>
              {bal?.substring(0, 12) || "0.00"}
            </Text>
          </View>
        </View>
        {/* <MaterialIcons name="remove-red-eye" size={20} color={"white"} /> */}
      </View>
      <View style={globalStyles.balanceRateItem}>
        <Text style={globalStyles.balanceRateText}>
          1 BTC ≈ Kes{" "}
          {`${rates?.value
            ?.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </Text>
      </View>
    </View>
  );
};

export default BalanceCard;
