import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";
import moment from "moment";

const ActivityItem = ({ tx, rates }) => {
  const calculateAmount = (inputted, required) => {
    if (tx?.grossCurrency?.toUpperCase() === "BTC" && required === "KES")
      return (inputted * rates?.value).toFixed(2).toString();
    if (tx?.grossCurrency?.toUpperCase() !== "BTC" && required === "BTC")
      return (inputted / rates?.value).toFixed(10).toString();
    if (tx?.grossCurrency?.toUpperCase() === "KES")
      return inputted
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return inputted.toString();
  };

  const getTxName = () => {
    if (tx.incoming)
      return `${tx.senderFirstName.toUpperCase()} ${tx.senderLastName.toUpperCase()}`;
    if (tx.outgoing)
      return `${tx.recipientFirstName.toUpperCase()} ${tx.recipientLastName.toUpperCase()}`;
  };

  return (
    <TouchableOpacity
      style={globalStyles.activityItem}
      key={tx.txId}
      onPress={() => console.log(tx, tx.incoming, tx.outgoing)}
    >
      <View style={globalStyles.activityItemTop}></View>
      <View style={globalStyles.activityItemMid}>
        <View style={globalStyles.frequentsTxItemIcon}>
          <MaterialIcons name="account-circle" size={50} color={"white"} />
        </View>
        <View style={globalStyles.activityItemText}>
          <Text style={globalStyles.activityItemBtc}>
            {`${(tx.incoming && "+") || (tx.outgoing && "-") || "+"} BTC `}
            {calculateAmount(Number(tx.grossValue), "BTC")}
          </Text>

          <Text style={globalStyles.activityItemName}>{getTxName()}</Text>

          <Text style={globalStyles.activityItemKes}>
            {`≈ Kes `}
            {calculateAmount(Number(tx.grossValue), "KES")}
          </Text>
        </View>
        <View style={globalStyles.activityItemMidArrow}>
          <MaterialIcons
            name={
              (tx.incoming && "arrow-downward") ||
              (tx.outgoing && "arrow-upward") ||
              "arrow-downward"
            }
            size={23}
            color={
              (tx.incoming && "green") || (tx.outgoing && "red") || "green"
            }
          />
        </View>
      </View>
      <View style={globalStyles.activityItemBottom}>
        <Text style={globalStyles.activityItemTime}>
          {moment(tx.timestamp).format("dddd, Do MMM YYYY, h:mm A")}
        </Text>
        <View style={globalStyles.activityItemStatusView}>
          <Text
            style={[
              globalStyles.activityItemStatus,
              tx.status === "INPROGRESS"
                ? { color: "orange" }
                : tx.status === "SETTLED"
                ? { color: "green" }
                : null,
            ]}
          >
            {tx.status}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ActivityItem;
