import React, { useEffect, useState } from "react";
import { View, Text, Platform, Image, ScrollView } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { globalStyles } from "../styles/global";
import { TextInput } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import CustomButton from "./CustomButton";
import * as Burnt from "burnt";
import { RNS3 } from "react-native-aws3";
import { useSelector } from "react-redux";

const options = {
  keyPrefix: "verif/",
  bucket: process.env.S3_BUCKET,
  region: process.env.REGION,
  accessKey: process.env.ACCESSKEY,
  secretKey: process.env.SECRETKEY,
};

const InputVerificationUpload = ({ handleSubmit, formInfo, loading }) => {
  const { user, merchant } = useSelector((state) => state.nav);
  const [idFront, setIdFront] = useState(null);
  const [idBack, setIdBack] = useState(null);
  const [selfie, setSelfie] = useState(null);
  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (!idFront) setCanContinue(false);
    if (!idBack) setCanContinue(false);
    if (!selfie) setCanContinue(false);
    if (idFront && idBack && selfie) setCanContinue(true);

    (async () => {
      if (Platform.OS !== "web") {
        const libraryStatus =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (libraryStatus.status !== "granted") {
          alert("Please grant Camera roll permissions!");
        }

        const cameraStatus = await ImagePicker.requestCameraPermissionsAsync();
        if (cameraStatus.status !== "granted") {
          alert("Please grant Camera permissions!");
        }
      }
    })();
  }, [idFront, idBack, selfie]);

  const submitVerification = () => {
    if (!idFront)
      return Burnt.toast({
        title: "Select ID photo", // required
        message: "Must provide ID Front photo",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    if (!idBack)
      return Burnt.toast({
        title: "Select ID photo", // required
        message: "Must provide ID Back photo",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    if (!selfie)
      return Burnt.toast({
        title: "Select Selfie photo", // required
        message: "Must provide Selfie photo",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    // Upload photos to S3 bucket and return S3 url to parent function (Verification)
    const frontFile = {
      uri: idFront,
      name: `${user?.firstName || merchant?.merchantName}-${
        user?.phone || merchant?.phone
      }-front.jpeg`,
      type: "image/jpeg",
    };

    const backFile = {
      uri: idBack,
      name: `${user?.firstName || merchant?.merchantName}-${
        user?.phone || merchant?.phone
      }-back.jpeg`,
      type: "image/jpeg",
    };

    const selfieFile = {
      uri: selfie,
      name: `${user?.firstName || merchant?.merchantName}-${
        user?.phone || merchant?.phone
      }-selfie.jpeg`,
      type: "image/jpeg",
    };

    loading(true);
    RNS3.put(frontFile, options)
      .then((response) => {
        if (response.status !== 201)
          Burnt.toast({
            title: "Upload failed", // required
            message: "Failed to upload ID front photo",
            preset: "error", // or "error", "none", "custom"
            haptic: "error", // or "success", "warning", "error"
            duration: 2, // duration in seconds
            shouldDismissByDrag: true,
          });
        else {
          console.log(
            "Successfully uploaded ID front to s3 bucket url: ",
            response.body.postResponse.location
          );
          setIdFront(response?.body?.postResponse?.location);
        }

        RNS3.put(backFile, options)
          .then((response) => {
            if (response.status !== 201)
              Burnt.toast({
                title: "Upload failed", // required
                message: "Failed to upload ID back photo",
                preset: "error", // or "error", "none", "custom"
                haptic: "error", // or "success", "warning", "error"
                duration: 2, // duration in seconds
                shouldDismissByDrag: true,
              });
            else {
              console.log(
                "Successfully uploaded ID back to s3 bucket url: ",
                response.body.postResponse.location
              );
              setIdBack(response?.body?.postResponse?.location);
            }

            RNS3.put(selfieFile, options)
              .then((response) => {
                if (response.status !== 201)
                  Burnt.toast({
                    title: "Upload failed", // required
                    message: "Failed to upload selfie photo",
                    preset: "error", // or "error", "none", "custom"
                    haptic: "error", // or "success", "warning", "error"
                    duration: 2, // duration in seconds
                    shouldDismissByDrag: true,
                  });
                else {
                  console.log(
                    "Successfully uploaded selfie to s3 bucket url: ",
                    response.body.postResponse.location
                  );
                  setSelfie(response?.body?.postResponse?.location);
                  loading(false);

                  console.log("form info >> ", formInfo);

                  return handleSubmit({
                    firstName: formInfo?.firstName,
                    lastName: formInfo?.lastName,
                    govId: formInfo?.govId,
                    idFront,
                    idBack,
                    selfie,
                  });
                }
              })
              .catch((error) => {
                console.log("Error occured on selfie upload - ", error);
              });
          })
          .catch((error) => {
            console.log("Error occured on back upload - ", error);
          });
      })
      .catch((error) => {
        console.log("Error occured on front upload - ", error);
      });
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
      allowsMultipleSelection: false,
    });

    if (!result.canceled) {
      setIdFront(result.assets[0]?.uri);
      console.log("updated>> idfront");
    }
  };

  const pickIdFront = () => {
    (async () => {
      await pickImage();
    })();
  };

  const pickIdBack = () => {
    (async () => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
        allowsMultipleSelection: false,
      });

      if (!result.canceled) {
        setIdBack(result.assets[0]?.uri);
        console.log("updated>> idback");
      }
    })();
  };

  const pickSelfie = () => {
    (async () => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
      });

      if (!result.canceled) {
        setSelfie(result.assets[0]?.uri);
        console.log("updated>> selfie");
      }
    })();
  };

  return (
    <ScrollView style={globalStyles.verificationView}>
      <Text style={globalStyles.verificationText}>
        Please ensure the photos are clear and your face and details are
        legible.
      </Text>

      <TouchableOpacity onPress={pickIdFront}>
        <View style={globalStyles.verificationImageView}>
          <View style={globalStyles.verificationImageSelect}>
            <TextInput
              autoFocus
              style={globalStyles.textInput}
              readOnly
              value="Upload ID Front"
            />
            <MaterialIcons name={"file-upload"} color={"white"} size={28} />
          </View>
          <Image
            source={{
              uri: idFront,
              cache: "reload",
            }}
            style={globalStyles.verificationImage}
            key={idFront}
          />
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={pickIdBack}>
        <View style={globalStyles.verificationImageView}>
          <View style={globalStyles.verificationImageSelect}>
            <TextInput
              autoFocus
              style={globalStyles.textInput}
              readOnly
              value="Upload ID Back"
            />
            <MaterialIcons name={"file-upload"} color={"white"} size={28} />
          </View>
          <Image
            source={{
              uri: idBack,
              cache: "reload",
            }}
            style={globalStyles.verificationImage}
            key={idBack}
          />
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={pickSelfie}>
        <View style={globalStyles.verificationImageView}>
          <View style={globalStyles.verificationImageSelect}>
            <TextInput
              autoFocus
              style={globalStyles.textInput}
              readOnly
              value="Upload Selfie"
            />
            <MaterialIcons name={"file-upload"} color={"white"} size={28} />
          </View>
          <Image
            source={{
              uri: selfie,
              cache: "reload",
            }}
            style={globalStyles.verificationImage}
            key={selfie}
          />
        </View>
      </TouchableOpacity>

      <CustomButton
        disabled={!canContinue}
        preferred={canContinue}
        onPress={submitVerification}
        text={"Submit"}
      />
    </ScrollView>
  );
};

export default InputVerificationUpload;
