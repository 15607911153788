import { View } from "react-native";
import React, { useEffect } from "react";
import NavHeader from "../components/NavHeader";
import { globalStyles } from "../styles/global";
import PageItem from "../components/PageItem";

const Selector = ({ navigation, route }) => {
  const { title, titleDesc, pages } = route.params;

  return (
    <View style={globalStyles.selector}>
      <NavHeader
        navigation={navigation}
        title={title === "Send" ? "Send or Pay" : title}
        description={titleDesc}
      />
      {pages?.map((page) => (
        <PageItem
          title={page.title}
          description={page.description}
          destination={page.destination}
          navigation={navigation}
          key={Math.random()}
        />
      ))}
    </View>
  );
};

export default Selector;
