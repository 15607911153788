import { View, Text, ScrollView } from "react-native";
import React from "react";
import NavHeader from "../components/NavHeader";
import { globalStyles } from "../styles/global";
import ActivityItem from "../components/ActivityItem";
import tw from "tailwind-react-native-classnames";
import { useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { getTransactions } from "../services";

const Notifications = ({}) => {
  const navigation = useNavigation();
  const { user, merchant, rates } = useSelector((state) => state.nav);
  const [history, setHistory] = React.useState([]);

  const sortHistory = (rawHistoryArr) => {
    let newHistory = [];
    rawHistoryArr.map((histItem) => {
      const historyItem = { ...histItem };
      switch (historyItem.type) {
        case "USERSEND":
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone
          )
            historyItem["outgoing"] = true;
          if (
            historyItem.recipient === user?.phone ||
            historyItem.recipient === merchant?.phone
          )
            if (!historyItem.outgoing) historyItem["incoming"] = true;
          break;

        case "MERCHANTPAY":
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone ||
            historyItem.sender === merchant?.merchantNo
          )
            historyItem["outgoing"] = true;
          if (
            historyItem.recipient === user?.phone ||
            historyItem.recipient === merchant?.phone ||
            historyItem.recipient === merchant?.merchantNo
          )
            if (!historyItem.outgoing) historyItem["incoming"] = true;
          break;

        case "DEPOSIT":
          if (
            historyItem.recipient === user?.phone ||
            historyItem.recipient === merchant?.phone
          )
            historyItem["incoming"] = true;
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone
          )
            if (!historyItem.incoming) historyItem["outgoing"] = true;
          break;

        case "WITHDRAW":
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone
          )
            historyItem["outgoing"] = true;
          break;

        default:
          break;
      }
      newHistory.push(historyItem);
    });

    newHistory.sort(function (a, b) {
      return b.timestamp - a.timestamp;
    });
    return newHistory;
  };

  React.useEffect(() => {
    const txPayload = {
      sender: user?.phone || merchant?.phone,
      recipient: user?.phone || merchant?.phone,
    };

    getTransactions(txPayload, (response) => {
      if (response?.length > 0) setHistory(sortHistory([...response]));
    });
  }, []);

  return (
    <View style={globalStyles.notifications}>
      <NavHeader navigation={navigation} title="Notifications" />

      <ScrollView style={tw`px-3`}>
        {history?.length === 0 && (
          <Text style={globalStyles.emptyListLbl}>
            Your notifications will show up here.
          </Text>
        )}
        {history?.map((item) => (
          <ActivityItem tx={item} key={Math.random()} rates={rates} />
        ))}
      </ScrollView>
    </View>
  );
};

export default Notifications;
