import { View, Text, TextInput } from "react-native";
import React, { useEffect, useState } from "react";
import { globalColors, globalStyles } from "../styles/global";
import SelectDropdown from "react-native-select-dropdown";
import { CONFIG } from "../services/config";
import CustomButton from "./CustomButton";
import { MaterialIcons } from "@expo/vector-icons";

const InputPin = ({ showPin, recipient, handleNextStep }) => {
  const [canContinue, setCanContinue] = useState(false);
  const [inputtedPin, setInputtedPin] = useState("");

  useEffect(() => {
    if (inputtedPin.length === 4) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [inputtedPin]);

  const doContinue = () => {
    handleNextStep(inputtedPin);
  };

  return (
    <View style={globalStyles.inputPin}>
      <View style={globalStyles.inputPinInner}>
        <Text style={globalStyles.inputAmountRecipientName}>
          {recipient?.txDesc}
        </Text>
        <View style={globalStyles.inputPinView}>
          <TextInput
            autoFocus
            style={globalStyles.inputPinText}
            placeholder={`Enter your 4-digit PIN`}
            placeholderTextColor={globalColors().CARD_ITEM}
            keyboardType="numeric"
            secureTextEntry={!showPin}
            onChangeText={setInputtedPin}
            maxLength={4}
          />
          <Text style={globalStyles.inputPinFee}>Transaction cost: 0 KES</Text>
        </View>
      </View>

      <CustomButton
        disabled={!canContinue}
        preferred={canContinue}
        text={"Confirm"}
        onPress={doContinue}
      />
    </View>
  );
};

export default InputPin;
