import React, { useState } from "react";
import { View, Text, TextInput } from "react-native";
import { globalStyles } from "../styles/global";
import { Formik } from "formik";
import CustomButton from "./CustomButton";

const CompleteMerchantSignupForm = ({ handleSignup }) => {
  const [showPin, setShowPin] = useState(false);
  const [canContinue, setCanContinue] = useState(true);

  return (
    <View style={globalStyles.completeSignupContainer}>
      <Text style={globalStyles.completeSignupText}>
        You're almost there, please provide your business name, contact persons'
        name and set a secure 4-digit PIN.
      </Text>
      <Formik
        initialValues={{
          merchantName: "",
          repName: "",
          pin: "",
          confirmpin: "",
        }}
        onSubmit={(values) => handleSignup(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View>
            <TextInput
              onChangeText={handleChange("merchantName")}
              onBlur={handleBlur("merchantName")}
              value={values.merchantName}
              style={globalStyles.textInput}
              placeholder="Business Name"
              autoFocus
            />

            <TextInput
              onChangeText={handleChange("repName")}
              onBlur={handleBlur("repName")}
              value={values.repName}
              style={globalStyles.textInput}
              placeholder="Contact Name"
            />

            <TextInput
              secureTextEntry={!showPin}
              onChangeText={handleChange("pin")}
              onBlur={handleBlur("pin")}
              value={values.pin}
              style={globalStyles.textInput}
              placeholder="PIN"
              keyboardType="numeric"
              maxLength={4}
            />

            <TextInput
              secureTextEntry={!showPin}
              onChangeText={handleChange("confirmpin")}
              onBlur={handleBlur("confirmpin")}
              value={values.confirmpin}
              style={globalStyles.textInput}
              placeholder="Confirm PIN"
              keyboardType="numeric"
              maxLength={4}
            />

            <CustomButton
              disabled={!canContinue}
              preferred={canContinue}
              text={"Finish"}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </View>
  );
};

export default CompleteMerchantSignupForm;
