import { View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import { globalColors, globalStyles } from "../styles/global";
import NavHeader from "../components/NavHeader";
import InputNumber from "../components/InputNumber";
import InputAmount from "../components/InputAmount";
import InputPin from "../components/InputPin";
import ProcessResult from "../components/ProcessResult";
import { useSelector } from "react-redux";
import { deposit, getMerchant, getUser, sendTransfer } from "../services";
import * as Burnt from "burnt";
import { useNavigation, useRoute } from "@react-navigation/native";

const ProcessFlow = ({ loading }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { phone, start, intent } = route.params;
  const [step, setStep] = useState(start || 0);
  const [result, setResult] = useState({});
  const { user, merchant, balance, rates } = useSelector((state) => state.nav);
  const [recipient, setRecipient] = useState(null);

  useEffect(() => {
    if (phone) return handleInputNumber(phone);
  }, [phone]);

  useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        // Prevent default behavior of leaving the screen
        e.preventDefault();

        if (step === 0 || step === 3) return navigation.dispatch(e.data.action);
        if (step > 0 && step < 3) return setStep(step - 1);
      }),
    [navigation, step]
  );

  useEffect(() => {
    const currentResult = { ...result };
    currentResult["recipient"] = recipient;
    setResult(currentResult);
  }, [recipient]);

  const getTitle = () => {
    switch (step) {
      case 0:
        return `Enter Phone Number (1/3)`;

      case 1:
        return `Enter Amount (2/3)`;

      case 2:
        return `Enter PIN (3/3)`;

      default:
        break;
    }
  };

  const calculateAmount = (inputted, required) => {
    if (recipient?.currency === "BTC" && required === "KES")
      return (inputted * rates?.value).toFixed(2).toString();
    if (recipient?.currency !== "BTC" && required === "BTC")
      return (inputted / rates?.value).toFixed(10).toString();
    return inputted.toString();
  };

  const genPayload = (pin) => {
    switch (intent) {
      case "SendMobile":
        return {
          type: "internal",
          initiator: user?.phone || merchant?.phone,
          sender: user?.phone || merchant?.phone,
          senderFirstName: user?.firstName || merchant?.merchantName,
          senderLastName: user?.lastName || "",
          recipient: recipient?.phone,
          recipientFirstName: recipient?.firstName || recipient?.lastName,
          recipientLastName: recipient?.firstName || recipient?.lastName,
          amount: calculateAmount(Number(recipient?.amount), "BTC"),
          pin,
          inOut: "BTC-BTC",
          currency: "btc",
          txType: "USERSEND",
        };

      case "PayMerchant":
        return {
          type: "internal",
          initiator: user?.phone || merchant?.phone,
          sender: user?.phone || merchant?.phone,
          senderFirstName: user?.firstName || merchant?.merchantName,
          senderLastName: user?.lastName || "",
          recipientFirstName: recipient?.firstName || recipient?.lastName,
          recipientLastName: recipient?.firstName || recipient?.lastName,
          recipient: recipient?.phone,
          amount: calculateAmount(Number(recipient?.amount), "BTC"),
          pin,
          inOut: "BTC-BTC",
          currency: "btc",
          txType: "MERCHANTPAY",
        };

      case "Deposit":
        return {
          type: "internal",
          txType: "DEPOSIT",
          initiator: user?.phone || merchant?.phone,
          senderFirstName: user?.firstName || merchant?.merchantName,
          senderLastName: user?.lastName || "",
          recipientFirstName: recipient?.firstName || recipient?.lastName,
          recipientLastName: recipient?.firstName || recipient?.lastName,
          inOut: "MPESA-BTC",
          method: "MPESA",
          payer: recipient?.phone,
          amount: calculateAmount(Number(recipient?.amount), "KES"),
          currency: "KES",
          payerCountry: "KE",
          purpose: "BUY_BTC",
          intent: "BUY_CRYPTO",
          beneficiary: [
            {
              address: user?.phone || merchant?.phone,
              isMerchant: merchant?.phone && true,
              amount: calculateAmount(Number(recipient?.amount), "KES"),
              currency: "BTC",
              basePair: "KES",
            },
          ],
        };

      case "Withdraw":
        return {
          type: "withdraw",
          initiator: user?.phone || merchant?.phone,
          sender: user?.phone || merchant?.phone,
          senderFirstName: user?.firstName || merchant?.merchantName,
          senderLastName: user?.lastName || "",
          recipientFirstName: recipient?.firstName || recipient?.lastName,
          recipientLastName: recipient?.firstName || recipient?.lastName,
          recipient: recipient?.phone,
          amount: calculateAmount(Number(recipient?.amount), "BTC"),
          method: "MPESA",
          pin,
          inOut: "BTC-KES",
          currency: "btc",
          txType: "WITHDRAW",
        };

      default:
        break;
    }
  };

  const handleInputNumber = (phone) => {
    loading(true);
    let payload = { phone };
    setRecipient({
      name: "",
      phone,
      firstName: "",
      lastName: "",
      profileImg: "",
    });
    if (intent === "SendMobile")
      getUser(payload, (response) => {
        loading(false);
        // console.log("gotten user >> ", response);
        if (response?.error || response?.msg || response?.message) {
          return Burnt.toast({
            title: response?.error || response?.msg || response?.message, // required
            preset: response?.error ? "error" : "done", // or "error", "none", "custom"
            haptic: response?.error ? "warning" : "success", // or "success", "warning", "error"
            duration: 2, // duration in seconds
            shouldDismissByDrag: true,
          });
        }
        if (response) {
          setRecipient({
            name: `${response?.firstName || ""} ${response?.lastName || ""}`,
            phone,
            firstName: response?.firstName,
            lastName: response?.lastName,
            profileImg: response?.profileImg || "",
          });
        }
        if (step === 0) setStep(step + 1);
      });

    if (intent === "PayMerchant")
      getMerchant(payload, (response) => {
        loading(false);
        if (response?.error || response?.msg || response?.message) {
          return Burnt.toast({
            title: response?.error || response?.msg || response?.message, // required
            preset: response?.error ? "error" : "done", // or "error", "none", "custom"
            haptic: response?.error ? "warning" : "success", // or "success", "warning", "error"
            duration: 2, // duration in seconds
            shouldDismissByDrag: true,
          });
        }
        if (response) {
          setRecipient({
            name: response?.merchantName || "",
            phone,
            firstName: response?.merchantName,
            lastName: "",
            profileImg: response?.profileImg || "",
          });
        }
        if (step === 0) setStep(step + 1);
      });

    if (intent === "Deposit" || intent === "Withdraw")
      if (step === 0) setStep(step + 1);
  };

  const handleInputAmount = ({ amount, currency, txDesc }) => {
    const currentRecObj = { ...recipient };
    currentRecObj["amount"] = amount;
    currentRecObj["currency"] = currency;
    currentRecObj["txDesc"] = txDesc;
    setRecipient(currentRecObj);
    setStep(step + 1);
  };

  const handleInputPin = (pin) => {
    loading(true);
    let payload = genPayload(pin);

    switch (intent) {
      case "SendMobile":
        sendTransfer(payload, (response) => {
          loading(false);
          if (response?.error)
            return Burnt.toast({
              title: response?.error, // required
              preset: "error", // or "error", "none", "custom"
              haptic: "warning", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
          if (response?.msg) {
            Burnt.toast({
              title: response?.msg || response?.message, // required
              preset: "done", // or "error", "none", "custom"
              haptic: "success", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
            const currentResult = { ...result };
            currentResult["response"] = response;
            setResult(currentResult);
            setStep(step + 1);
          }
        });
        break;

      case "PayMerchant":
        sendTransfer(payload, (response) => {
          loading(false);
          if (response?.error)
            return Burnt.toast({
              title: response.error, // required
              preset: "error", // or "error", "none", "custom"
              haptic: "warning", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
          if (response?.msg) {
            Burnt.toast({
              title: response?.msg || response?.message, // required
              preset: "done", // or "error", "none", "custom"
              haptic: "success", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
            const currentResult = { ...result };
            currentResult["response"] = response;
            setResult(currentResult);
            setStep(step + 1);
          }
        });
        break;

      case "Deposit":
        deposit(payload, (response) => {
          loading(false);
          if (response?.error)
            return Burnt.toast({
              title: response.error, // required
              preset: "error", // or "error", "none", "custom"
              haptic: "warning", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
          if (response?.msg) {
            Burnt.toast({
              title: response?.msg || response?.message, // required
              preset: "done", // or "error", "none", "custom"
              haptic: "success", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
            const currentResult = { ...result };
            currentResult["response"] = response;
            setResult(currentResult);
            setStep(step + 1);
          }
        });
        break;

      case "Withdraw":
        sendTransfer(payload, (response) => {
          loading(false);
          if (response?.error)
            return Burnt.toast({
              title: response.error, // required
              preset: "error", // or "error", "none", "custom"
              haptic: "warning", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
          if (response?.msg) {
            Burnt.toast({
              title: response?.msg || response?.message, // required
              preset: "done", // or "error", "none", "custom"
              haptic: "success", // or "success", "warning", "error"
              duration: 2, // duration in seconds
              shouldDismissByDrag: true,
            });
            const currentResult = { ...result };
            currentResult["response"] = response;
            setResult(currentResult);
            setStep(step + 1);
          }
        });
        break;

      default:
        break;
    }
  };

  const handleCloseProcess = () => {
    navigation.replace("Dashboard");
  };

  return (
    <View style={globalStyles.dashboard}>
      {step !== 3 && (
        <NavHeader navigation={navigation} process={true} title={getTitle()} />
      )}

      {step === 0 && (
        <InputNumber recipient={recipient} handleNextStep={handleInputNumber} />
      )}

      {step === 1 && (
        <InputAmount
          intent={intent}
          recipient={recipient}
          handleNextStep={handleInputAmount}
          balance={balance}
          rates={rates}
        />
      )}

      {step === 2 && (
        <InputPin recipient={recipient} handleNextStep={handleInputPin} />
      )}

      {step === 3 && (
        <ProcessResult
          handleCloseProcess={handleCloseProcess}
          result={result}
        />
      )}
    </View>
  );
};

export default ProcessFlow;
