import "setimmediate";
import "react-native-gesture-handler";
import { Toaster } from "burnt/web";
import { registerRootComponent } from "expo";
import Spinner from "react-native-loading-spinner-overlay";
import { KeyboardAvoidingView, Platform } from "react-native";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { RootSiblingParent } from "react-native-root-siblings";
import LoginScreen from "../screens/LoginScreen";
import Dashboard from "../screens/Dashboard";
import Notifications from "../screens/Notifications";
import Selector from "../screens/Selector";
import SendMobile from "../screens/SendMobile";
import PayMerchant from "../screens/PayMerchant";
import ProcessFlow from "../screens/ProcessFlow";
import ReceiveBlockchain from "../screens/ReceiveBlockchain";
import ReceiveMobile from "../screens/ReceiveMobile";
import WithdrawMobileMoney from "../screens/WithdrawMobileMoney";
import DepositMobileMoney from "../screens/DepositMobileMoney";
import SplashScreen from "../screens/SplashScreen";
import SignupScreen from "../screens/SignupScreen";
import Verification from "../screens/Verification";
import MerchantLoginScreen from "../screens/MerchantLoginScreen";
import MerchantSignupScreen from "../screens/MerchantSignupScreen";
import React from "react";
import { globalColors } from "../styles/global";

const Stack = createStackNavigator();

function AppStack({ route, loading }) {
  return (
    <Stack.Navigator
      initialRouteName="Login"
      screenOptions={{
        headerShown: false,
        cardStyle: {
          flex: 1,
        },
      }}
    >
      <Stack.Screen name="Splash" component={SplashScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="MerchantSignup" component={MerchantSignupScreen} />
      <Stack.Screen
        name="Login"
        children={() => <LoginScreen loading={loading} />}
      />
      <Stack.Screen
        name="MerchantLogin"
        children={() => <MerchantLoginScreen loading={loading} />}
      />
      <Stack.Screen
        name="Dashboard"
        children={() => <Dashboard loading={loading} />}
      />
      <Stack.Screen name="Notifications" children={() => <Notifications />} />
      <Stack.Screen name="Selector" component={Selector} />
      <Stack.Screen
        name="Process"
        children={() => <ProcessFlow loading={loading} />}
      />
      <Stack.Screen
        name="SendMobile"
        children={() => <SendMobile loading={loading} />}
      />
      <Stack.Screen
        name="PayMerchant"
        children={() => <PayMerchant loading={loading} />}
      />
      <Stack.Screen
        name="ReceiveBlockchain"
        children={() => <ReceiveBlockchain loading={loading} />}
      />
      <Stack.Screen
        name="ReceiveMobile"
        children={() => <ReceiveMobile loading={loading} />}
      />
      <Stack.Screen
        name="WithdrawMobile"
        children={() => <WithdrawMobileMoney loading={loading} />}
      />
      <Stack.Screen
        name="DepositMobile"
        children={() => <DepositMobileMoney loading={loading} />}
      />
      <Stack.Screen
        name="Verification"
        children={() => <Verification loading={loading} />}
      />
    </Stack.Navigator>
  );
}

export default function App() {
  const Drawer = createDrawerNavigator();
  const [spinner, setSpinner] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (spinner) setSpinner(!spinner);
    }, 10000);
  }, [spinner]);

  return (
    <RootSiblingParent>
      <Provider store={store}>
        <NavigationContainer>
          <SafeAreaProvider>
            <Spinner
              visible={spinner}
              color={globalColors().LUMINOUS_GREEN_HEX}
              animation="slide"
            />
            <KeyboardAvoidingView
              behavior={Platform.OS === "ios" ? "padding" : "height"}
              style={{ flex: 1 }}
              keyboardVerticalOffset={Platform.OS === "ios" ? -64 : 0}
            >
              <Drawer.Navigator
                initialRouteName="App"
                screenOptions={{
                  headerShown: false,
                }}
              >
                <Drawer.Screen
                  name="App"
                  children={() => <AppStack loading={setSpinner} />}
                />
              </Drawer.Navigator>
            </KeyboardAvoidingView>
          </SafeAreaProvider>
        </NavigationContainer>
      </Provider>
      <Toaster position="top-center" />
    </RootSiblingParent>
  );
}

registerRootComponent(App);
