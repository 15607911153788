import { View, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import CustomHeader from "../components/CustomHeader";
import { globalColors, globalStyles } from "../styles/global";
import BalanceCard from "../components/BalanceCard";
import DashActionsCard from "../components/DashActionsCard";
import FrequentsCard from "../components/FrequentsCard";
import ActivityCard from "../components/ActivityCard";
import { getBalance, getRates, getTransactions } from "../services";
import { useDispatch, useSelector } from "react-redux";
import {
  setBalance,
  setContacts,
  setHistory,
  setRates,
} from "../redux/navSlice";
import * as Burnt from "burnt";
import { useNavigation } from "@react-navigation/native";

const Dashboard = ({}) => {
  const { user, merchant, balance, rates } = useSelector((state) => state.nav);
  const [currency, setCurrency] = useState(3);
  const [history, setHistory] = React.useState([]);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const sortHistory = (rawHistoryArr) => {
    let newHistory = [];
    rawHistoryArr.map((histItem) => {
      const historyItem = { ...histItem };
      switch (historyItem.type) {
        case "USERSEND":
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone
          )
            historyItem["outgoing"] = true;
          if (
            historyItem.recipient === user?.phone ||
            historyItem.recipient === merchant?.phone
          )
            if (!historyItem.outgoing) historyItem["incoming"] = true;
          break;

        case "MERCHANTPAY":
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone ||
            historyItem.sender === merchant?.merchantNo
          )
            historyItem["outgoing"] = true;
          if (
            historyItem.recipient === user?.phone ||
            historyItem.recipient === merchant?.phone ||
            historyItem.recipient === merchant?.merchantNo
          )
            if (!historyItem.outgoing) historyItem["incoming"] = true;
          break;

        case "DEPOSIT":
          if (
            historyItem.recipient === user?.phone ||
            historyItem.recipient === merchant?.phone
          )
            historyItem["incoming"] = true;
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone
          )
            if (!historyItem.incoming) historyItem["outgoing"] = true;
          break;

        case "WITHDRAW":
          if (
            historyItem.sender === user?.phone ||
            historyItem.sender === merchant?.phone
          )
            historyItem["outgoing"] = true;
          break;

        default:
          break;
      }
      newHistory.push(historyItem);
    });

    newHistory.sort(function (a, b) {
      return b.timestamp - a.timestamp;
    });
    return newHistory;
  };

  useEffect(() => {
    const payload = {
      phone: user?.phone || merchant?.phone,
      currency:
        user?.wallets[0]?.currency || merchant?.wallets[0]?.currency || "btc",
      isMerchant: merchant ? "true" : "false",
    };
    const ratesPayload = {
      currency:
        user?.wallets[0]?.currency || merchant?.wallets[0]?.currency || "btc",
      basePair: "KES",
    };

    const txPayload = {
      sender: user?.phone || merchant?.phone,
      recipient: user?.phone || merchant?.phone,
    };

    if (user || merchant) {
      getRates(ratesPayload, (response) => {
        dispatch(setRates(response));
      });
      getBalance(payload, (response) => {
        if (response?.error)
          return Burnt.toast({
            title: response?.error || response?.msg, // required
            preset: "done", // or "error", "none", "custom"
            haptic: "success", // or "success", "warning", "error"
            duration: 2, // duration in seconds
            shouldDismissByDrag: true,
          });

        response.balInKes = (
          Number(response?.availableBalance) * rates?.value
        ).toFixed(2);
        dispatch(setBalance(response));
      });

      getTransactions(txPayload, (response) => {
        if (response?.length > 0) setHistory(sortHistory([...response]));
      });
    }
  }, [user, merchant]);

  return (
    <View style={globalStyles.dashboard}>
      <CustomHeader navigation={navigation} />
      <ScrollView style={globalStyles.scrollViewStyle}>
        <BalanceCard
          balance={balance}
          rates={rates}
          merchant={merchant}
          currency={currency}
          setCurrency={setCurrency}
        />
        <DashActionsCard navigation={navigation} />
        <FrequentsCard history={history} />
        <ActivityCard history={history} rates={rates} />
      </ScrollView>
    </View>
  );
};

export default Dashboard;
