import React, { useState } from "react";
import { View, Text, TextInput, ScrollView } from "react-native";
import { Formik } from "formik";
import CustomButton from "./CustomButton";
import { globalStyles } from "../styles/global";
import * as Burnt from "burnt";

const InputVerificationForm = ({ handleContinue }) => {
  const [canContinue, setCanContinue] = useState(true);

  const submitId = (values) => {
    if (!values.govId)
      return Burnt.toast({
        title: "Enter ID", // required
        message: "Must provide National ID Number",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    if (!values.firstName)
      return Burnt.toast({
        title: "Enter First Name", // required
        message: "Must provide First Name",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    if (!values.lastName)
      return Burnt.toast({
        title: "Enter Surname", // required
        message: "Must provide Surname",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    handleContinue(values);
  };

  return (
    <ScrollView style={globalStyles.verificationView}>
      <Text style={globalStyles.verificationText}>
        Please have your government issued National ID.
      </Text>

      <Formik
        initialValues={{ govId: "", firstName: "", lastName: "" }}
        onSubmit={(values) => submitId(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View>
            <TextInput
              autoFocus
              style={globalStyles.textInput}
              placeholder={`National ID Number`}
              onChangeText={handleChange("govId")}
              onBlur={handleBlur("govId")}
              value={values.govId}
            />

            <TextInput
              style={globalStyles.textInput}
              placeholder={`First Name`}
              onChangeText={handleChange("firstName")}
              onBlur={handleBlur("firstName")}
              value={values.firstName}
            />

            <TextInput
              style={globalStyles.textInput}
              placeholder={`Surname`}
              onChangeText={handleChange("lastName")}
              onBlur={handleBlur("lastName")}
              value={values.lastName}
            />

            <View>
              <CustomButton
                disabled={!canContinue}
                preferred={canContinue}
                onPress={handleSubmit}
                text={"Next"}
              />
            </View>
          </View>
        )}
      </Formik>
    </ScrollView>
  );
};

export default InputVerificationForm;
