import { View, Text, TextInput } from "react-native";
import React, { useEffect, useState } from "react";
import SelectDropdown from "react-native-select-dropdown";
import { CONFIG } from "../services/config";
import { globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";
import CustomButton from "./CustomButton";
import tw from "tailwind-react-native-classnames";

const VerifyPhone = ({ handleNextStep }) => {
  const [canContinue, setCanContinue] = useState(false);
  const [inputtedNumber, setInputtedNumber] = useState("");

  useEffect(() => {
    if (inputtedNumber.length >= 10) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [inputtedNumber]);

  const doContinue = () => {
    const formattedNo =
      "254" + inputtedNumber.substring(1, inputtedNumber.length);
    if (canContinue) handleNextStep(formattedNo);
  };

  return (
    <View style={globalStyles.verifyNumber}>
      <Text style={tw`text-white ml-6 mb-3`}>Enter Phone Number</Text>
      <View style={globalStyles.verifyNumberView}>
        <SelectDropdown
          data={CONFIG.supportedCountries}
          onSelect={(selectedItem, index) => {
            console.log(selectedItem, index);
          }}
          defaultValue={CONFIG.supportedCountries[0]}
          renderButton={(selectedItem, isOpened) => {
            return (
              <View style={globalStyles.dropdownButtonStyle}>
                <Text style={globalStyles.dropdownButtonTxtStyle}>
                  {(selectedItem && selectedItem.title) || "Select country"}
                </Text>
                <MaterialIcons
                  name={isOpened ? "keyboard-arrow-up" : "keyboard-arrow-down"}
                  color={"white"}
                  size={28}
                />
              </View>
            );
          }}
          renderItem={(item, index, isSelected) => {
            return (
              <View
                style={{
                  ...globalStyles.dropdownItemStyle,
                  ...(isSelected && { backgroundColor: "#D2D9DF" }),
                }}
              >
                <Text style={globalStyles.dropdownItemTxtStyle}>
                  {item.title}
                </Text>
              </View>
            );
          }}
          showsVerticalScrollIndicator={false}
          dropdownStyle={globalStyles.dropdownMenuStyle}
        />
        <TextInput
          style={globalStyles.verifyNumberText}
          placeholder={`07xxxxxxxx`}
          placeholderTextColor="grey"
          keyboardType="numeric"
          autoFocus
          onChangeText={setInputtedNumber}
          maxLength={10}
        />
      </View>

      <View style={tw`px-5`}>
        <CustomButton
          disabled={!canContinue}
          preferred={canContinue}
          text={"Continue"}
          onPress={doContinue}
        />
      </View>
    </View>
  );
};

export default VerifyPhone;
