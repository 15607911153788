import { View, Text, Animated, Easing } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import LottieView from "lottie-react-native";
import tw from "tailwind-react-native-classnames";
import { globalColors, globalStyles } from "../styles/global";

const AnimatedLottieView = Animated.createAnimatedComponent(LottieView);

const SplashScreen = () => {
  const animationProgress = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animationProgress.current, {
      toValue: 1,
      duration: 1200,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }, []);

  return (
    <View style={globalStyles.dashboard}>
      <View style={tw`w-full h-full`}>
        <AnimatedLottieView
          source={require("../assets/splash-animation.json")}
          imageAssetsFolder={"lottie/images"}
          style={tw`flex-1`}
          autoPlay
          loop
        />
      </View>
    </View>
  );
};

export default SplashScreen;
