import React, { useState } from "react";
import { View, Text, TextInput } from "react-native";
import { globalStyles } from "../styles/global";
import { Formik } from "formik";
import CustomButton from "./CustomButton";
import tw from "tailwind-react-native-classnames";

const CompleteSignupForm = ({ handleSignup }) => {
  const [showPin, setShowPin] = useState(false);
  const [canContinue, setCanContinue] = useState(true);

  const finishSignup = (values) => handleSignup(values);

  return (
    <View style={globalStyles.completeSignupContainer}>
      <Text style={globalStyles.completeSignupText}>
        You're almost there, please provide your name and set a secure 4-digit
        PIN.
      </Text>
      <Formik
        initialValues={{ firstName: "", lastName: "", pin: "", confirmpin: "" }}
        onSubmit={(values) => finishSignup(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <View>
            <TextInput
              onChangeText={handleChange("firstName")}
              onBlur={handleBlur("firstName")}
              value={values.firstName}
              style={globalStyles.textInput}
              placeholder="First Name"
              autoFocus
            />

            <TextInput
              onChangeText={handleChange("lastName")}
              onBlur={handleBlur("lastName")}
              value={values.lastName}
              style={globalStyles.textInput}
              placeholder="Last Name"
            />

            <TextInput
              secureTextEntry={!showPin}
              onChangeText={handleChange("pin")}
              onBlur={handleBlur("pin")}
              value={values.pin}
              style={globalStyles.textInput}
              placeholder="PIN"
              keyboardType="numeric"
              maxLength={4}
            />

            <TextInput
              secureTextEntry={!showPin}
              onChangeText={handleChange("confirmpin")}
              onBlur={handleBlur("confirmpin")}
              value={values.confirmpin}
              style={globalStyles.textInput}
              placeholder="Confirm PIN"
              keyboardType="numeric"
              maxLength={4}
            />

            <CustomButton
              disabled={!canContinue}
              preferred={canContinue}
              text={"Finish"}
              onPress={handleSubmit}
            />
          </View>
        )}
      </Formik>
    </View>
  );
};

export default CompleteSignupForm;
