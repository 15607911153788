const baseUrl = "https://hhwi9o5jvg.execute-api.us-east-1.amazonaws.com/Prod/";
const guestBase = `https://5920qlxr5l.execute-api.us-east-1.amazonaws.com/Prod/guest`;

export const ENDPOINTS = {
  baseUrl,
  guestBase,
  getBal: `${baseUrl}v1/wallets/balance`,
  getRates: `${baseUrl}v1/tokens/rate`,
  getTransactions: `${baseUrl}v1/transactions`, // /:currency
  getUser: `${baseUrl}v1/users`,
  getMerchant: `${baseUrl}v1/merchants`,
  verifyUserPhone: `${baseUrl}v1/users/verify/phone`,
  verifyMerchantPhone: `${baseUrl}v1/users/verify/phone`,
  userRegister: `${baseUrl}v1/users/register`,
  merchantRegister: `${baseUrl}v1/merchants/register`,
  userLogin: `${baseUrl}v1/users/login`,
  merchantLogin: `${baseUrl}v1/merchants/login`,
  getWalletBalance: `${baseUrl}v1/wallets/balance`,
  getCurrentRates: `${baseUrl}v1/tokens/rate`,
  submitKyc: `${baseUrl}v1/verification/submit`,
  checkKycStatus: `${baseUrl}v1/verification/status`,
  genOrGetAddress: `${baseUrl}v1/wallets/address`, // /:action/:user
  userCreateWallet: `${baseUrl}v1/users/wallets/generate`, // /:user
  merchantCreateWallet: `${baseUrl}v1/merchants/wallets/generate`, // /:user
  getWallet: `${baseUrl}v1/wallets`, // /:walletId
  userGetAllWallets: `${baseUrl}v1/users/wallets`, // /:user
  merchantGetAllWallets: `${baseUrl}v1/merchants/wallets`, // /:user
  sendToBlockchain: `${baseUrl}v1/transact/blockchain/send`,
  sendTransfer: `${baseUrl}v1/transact/transfer`,
  withdraw: `${baseUrl}v1/transact/pay`,
  deposit: `${baseUrl}v1/transact/buy`,
};
