import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import React, { useEffect, useState } from "react";
import NavHeader from "../components/NavHeader";
import { globalColors, globalStyles } from "../styles/global";
import { SearchBar } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import tw from "tailwind-react-native-classnames";
import ContactsCard from "../components/ContactsCard";
import * as Contacts from "expo-contacts";
import { useNavigation, useRoute } from "@react-navigation/native";
import * as Burnt from "burnt";

const cleanPhoneNumber = (rawPhone) => {
  let cleaned = "";
  if (!rawPhone) return cleaned;
  cleaned = rawPhone.replace(/\s/g, "");
  if (cleaned.length === 10)
    cleaned = "+254" + cleaned.substring(1, cleaned.length);
  return cleaned.slice(1, cleaned.length);
};

const filterNameAndNumber = (rawContactList) => {
  let filtered = [],
    name = "",
    number = "";

  rawContactList?.map((contactItem) => {
    name = contactItem?.name || "";
    if (contactItem?.phoneNumbers?.length > 0)
      number =
        contactItem?.phoneNumbers[0]?.number ||
        contactItem?.phoneNumbers[1]?.number ||
        "";
    filtered.push({ name, number });
  });
  return filtered;
};

const DepositMobileMoney = ({}) => {
  const intent = "Deposit";
  const frequents = [];
  const navigation = useNavigation();
  const route = useRoute();
  const { title, description } = route.params;
  const [contactsData, setContactsData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!search)
      (async () => {
        await getAllContacts();
      })();

    const currentContacts = [...contactsData];
    if (search) {
      const filtered = currentContacts.filter((contact) => {
        return contact.name.toLowerCase().includes(search?.toLowerCase());
      });
      setContactsData(filtered);
    }
  }, [search]);

  const getAllContacts = async () => {
    const { status } = await Contacts.requestPermissionsAsync();
    if (status === "granted") {
      const { data } = await Contacts.getContactsAsync({
        fields: [Contacts.Fields.PhoneNumbers],
      });

      if (data?.length > 0) {
        const filtered = filterNameAndNumber(data);
        setContactsData(filtered);
      }
    }
  };

  const handleEnterPhone = () => {
    navigation.navigate("Process", { intent });
  };

  const selectContact = (no) => {
    let phone = cleanPhoneNumber(no?.number);
    console.log("Selected >> ", phone);
    if (!phone)
      return Burnt.toast({
        title: "Please select contact", // required
        message: "Select contact to proceed",
        preset: "error", // or "error", "none", "custom"
        haptic: "warning", // or "success", "warning", "error"
        duration: 2, // duration in seconds
        shouldDismissByDrag: true,
      });

    if (phone)
      navigation.navigate("Process", {
        intent,
        phone,
        start: 1,
      });
  };

  return (
    <View style={globalStyles.dashboard}>
      <NavHeader
        navigation={navigation}
        title={title}
        description={"Enter phone number to make payment."}
      />

      <SearchBar
        containerStyle={{ backgroundColor: globalColors().BLACK_HEX }}
        inputContainerStyle={globalStyles.searchBarContainer}
        placeholder="Search contacts"
        onChangeText={setSearch}
        value={search}
        inputStyle={globalStyles.searchBar}
      />

      <TouchableOpacity
        style={globalStyles.enterPhoneNo}
        onPress={handleEnterPhone}
      >
        <Ionicons
          name="keypad"
          size={28}
          color={"white"}
          style={tw`ml-5 mr-3`}
        />
        <Text style={globalStyles.enterPhoneNoText}>Enter phone number</Text>
      </TouchableOpacity>

      <ScrollView>
        {frequents.length > 0 && (
          <ContactsCard
            title={"Frequents"}
            data={frequents}
            selectContact={selectContact}
          />
        )}
        <ContactsCard
          title={"Contacts"}
          data={contactsData}
          selectContact={selectContact}
        />
      </ScrollView>
    </View>
  );
};

export default DepositMobileMoney;
