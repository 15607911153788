import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";

const DashActionItem = ({
  title,
  titleDesc,
  icon,
  navigation,
  destination,
  pages,
}) => {
  const handleNav = () => {
    navigation.push(destination, { title, titleDesc, pages });
  };

  return (
    <TouchableOpacity style={globalStyles.dashActionItem} onPress={handleNav}>
      <View style={globalStyles.dashActionItemIcon}>
        <MaterialIcons name={icon} size={28} color={"black"} />
      </View>
      <Text style={globalStyles.dashActionItemText}>{title}</Text>
    </TouchableOpacity>
  );
};

export default DashActionItem;
