export const CONFIG = {
  supportedCurrencies: [
    { title: "BTC", icon: "BTC" },
    { title: "🇹🇿", icon: "TSH" },
    { title: "🇺🇬", icon: "UGX" },
    { title: "🇰🇪", icon: "KES" },
  ],
  supportedCountries: [
    { title: "🇰🇪", icon: "KE" },
    { title: "🇹🇿", icon: "TZ" },
    { title: "🇺🇬", icon: "UG" },
  ],
  whatsappSupport: "254713278107",
};
