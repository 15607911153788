import { View, Text } from "react-native";
import React from "react";
import tw from "tailwind-react-native-classnames";
import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";

const Banner = ({ handleClickBanner, bannerStyle, message, waiting }) => {
  return (
    <TouchableOpacity onPress={handleClickBanner}>
      <View
        style={[
          bannerStyle,
          { backgroundColor: waiting ? `orange` : `maroon` },
        ]}
      >
        <Ionicons
          name="warning-outline"
          size={28}
          color={"white"}
          style={tw`ml-10 mr-5`}
        />
        <Text style={tw`text-white`}>{message}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default Banner;
