import { View, Text, TextInput } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { globalColors, globalStyles } from "../styles/global";
import SelectDropdown from "react-native-select-dropdown";
import { CONFIG } from "../services/config";
import CustomButton from "./CustomButton";
import { MaterialIcons } from "@expo/vector-icons";
import * as Burnt from "burnt";

const InputAmount = ({ intent, recipient, handleNextStep, balance, rates }) => {
  const [canContinue, setCanContinue] = useState(false);
  const [inputtedAmount, setInputtedAmount] = useState("0.00");
  const [txDesc, setTxDesc] = useState("");
  const [currency, setCurrency] = useState(
    (intent === "Deposit" && CONFIG.supportedCurrencies[3].icon) ||
      (intent === "Withdraw" && CONFIG.supportedCurrencies[0].icon) ||
      CONFIG.supportedCurrencies[3].icon
  );
  const amountInputRef = useRef();
  const amountAlt1 = useRef();
  const amountAlt2 = useRef();
  const [bal, setBal] = useState([
    {
      currency: "BTC",
      amount: balance?.availableBalance?.substring(0, 12) || "0.00000",
    },
    {
      currency: "KES",
      amount: balance?.balInKes,
    },
  ]);

  useEffect(() => {
    switch (intent) {
      case "SendMobile":
        setTxDesc(
          `Send ${
            currency === "BTC"
              ? inputtedAmount.toString()
              : inputtedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          } ${currency} to ${recipient?.name?.toUpperCase()} ${
            recipient?.phone
          }`
        );
        break;
      case "PayMerchant":
        setTxDesc(
          `Pay ${
            currency === "BTC"
              ? inputtedAmount.toString()
              : inputtedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          } ${currency} to ${recipient?.name?.toUpperCase()} ${
            recipient?.phone
          }`
        );
        break;

      case "Deposit":
        setTxDesc(
          `Deposit ${
            currency === "BTC"
              ? inputtedAmount.toString()
              : inputtedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          } ${currency} from ${recipient?.name?.toUpperCase()} ${
            recipient?.phone
          } via Mpesa`
        );
        break;

      case "Withdraw":
        setTxDesc(
          `Withdraw ${
            currency === "BTC"
              ? inputtedAmount.toString()
              : inputtedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          } ${currency} to ${recipient?.name?.toUpperCase()} ${
            recipient?.phone
          } via Mpesa`
        );
        break;

      default:
        break;
    }
  }, [intent, inputtedAmount, recipient, currency]);

  useEffect(() => {
    if (intent === "Deposit" || intent === "Withdraw") {
      if (currency === "BTC") return amountAlt2.current.selectIndex(3);
      if (currency !== "BTC") return amountAlt2.current.selectIndex(0);
    }
    currency === "BTC" && amountAlt2.current.selectIndex(0);
    currency !== "BTC" && amountAlt2.current.selectIndex(3);
  }, [currency]);

  useEffect(() => {
    if (Number(inputtedAmount) > 0) {
      const currentBal = Number(
        bal?.find((value, index) => value.currency === "KES").amount
      );
      if (
        intent === "SendMobile" ||
        intent === "PayMerchant" ||
        intent === "Withdraw"
      )
        if (inputtedAmount > currentBal) {
          Burnt.toast({
            title: "Insufficient Balance", // required
            message: "Amount cannot exceed available balance",
            preset: "error", // or "error", "none", "custom"
            haptic: "warning", // or "success", "warning", "error"
            duration: 2, // duration in seconds
            shouldDismissByDrag: true,
          });
          return setCanContinue(false);
        }
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [inputtedAmount]);

  const genText = (position) => {
    switch (position) {
      case "alt1":
        if (intent === "SendMobile") return `I want to send`;
        if (intent === "PayMerchant") return `I want to pay`;
        if (intent === "Deposit") return `I want to deposit`;
        if (intent === "Withdraw") return `I want to withdraw`;

      case "alt2":
        if (intent === "SendMobile") return `They will receive`;
        if (intent === "PayMerchant") return `They will receive`;
        if (intent === "Deposit") {
          if (currency !== "BTC") return `I will receive`;
          return `I will pay`;
        }
        if (intent === "Withdraw") return `I will receive`;

      default:
        break;
    }
  };

  const convertCurrencies = () => {
    let initialAmount = Number(inputtedAmount),
      converted = 0;
    if (initialAmount === 0) return "0.00";
    if (intent === "Deposit" || intent === "Withdraw") {
      if (currency !== "BTC")
        return (initialAmount / rates?.value).toFixed(10).toString();

      if (currency === "BTC" && initialAmount > 0) {
        converted = initialAmount * rates?.value;
        return converted
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
    return initialAmount.toString();
  };

  const doContinue = () => {
    handleNextStep({ amount: inputtedAmount, currency, txDesc });
  };

  return (
    <View style={globalStyles.inputAmount}>
      <View style={globalStyles.inputAmountRecipient}>
        <MaterialIcons name="account-circle" size={65} color={"white"} />
        <Text style={globalStyles.inputAmountRecipientName}>{txDesc}</Text>
      </View>
      <View style={globalStyles.inputAmountInner}>
        <View style={globalStyles.inputAmountBalance}>
          <Text style={globalStyles.inputAmountBalanceText}>Available:</Text>
          <View style={globalStyles.inputAmountBalanceView}>
            {bal.map((balanceItem) => (
              <Text
                key={Math.random()}
                style={globalStyles.inputAmountBalanceItem}
              >
                {balanceItem.currency === "KES" && `≈`} {balanceItem.currency}{" "}
                {balanceItem.currency !== "BTC"
                  ? balanceItem.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : balanceItem.amount}
              </Text>
            ))}
          </View>
        </View>
        <View style={globalStyles.inputAmountView}>
          <Text style={globalStyles.inputAmountLabel}>{genText("alt1")}</Text>
          <View style={globalStyles.inputAmountInnerView}>
            <TextInput
              style={globalStyles.inputNumberText}
              placeholder={"0"}
              placeholderTextColor="white"
              keyboardType="numeric"
              autoFocus
              ref={amountInputRef}
              onChangeText={setInputtedAmount}
            />
            <SelectDropdown
              data={CONFIG.supportedCurrencies}
              onSelect={(selectedItem, index) => {
                console.log(selectedItem, index);
                setCurrency(selectedItem?.icon);
                setInputtedAmount("0.00");
                amountInputRef.current.clear();
              }}
              ref={amountAlt1}
              defaultValue={
                (intent === "Deposit" && CONFIG.supportedCurrencies[3]) ||
                (intent === "Withdraw" && CONFIG.supportedCurrencies[0]) ||
                CONFIG.supportedCurrencies[3]
              }
              renderButton={(selectedItem, isOpened) => {
                return (
                  <View style={globalStyles.dropdownButtonStyle}>
                    <Text style={globalStyles.dropdownButtonTxtStyle}>
                      {(selectedItem && selectedItem.title) ||
                        "Select currency"}
                    </Text>
                    <MaterialIcons
                      name={
                        isOpened ? "keyboard-arrow-up" : "keyboard-arrow-down"
                      }
                      color={"white"}
                      size={28}
                    />
                  </View>
                );
              }}
              renderItem={(item, index, isSelected) => {
                return (
                  <View
                    style={{
                      ...globalStyles.dropdownItemStyle,
                      ...(isSelected && { backgroundColor: "#D2D9DF" }),
                    }}
                  >
                    <Text style={globalStyles.dropdownItemTxtStyle}>
                      {item.title}
                    </Text>
                  </View>
                );
              }}
              showsVerticalScrollIndicator={false}
              dropdownStyle={globalStyles.dropdownMenuStyle}
            />
          </View>
        </View>
        <Text style={globalStyles.inputAmountLimit}>
          Max 0.019 BTC ≈{" "}
          {(0.019 * rates?.value)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          KES
        </Text>

        <View style={globalStyles.inputAmountView}>
          <Text style={globalStyles.inputAmountLabel}>{genText("alt2")}</Text>
          <View style={globalStyles.inputAmountInnerView}>
            <Text style={globalStyles.inputNumberText}>
              {convertCurrencies()}
            </Text>
            <SelectDropdown
              data={CONFIG.supportedCurrencies}
              onSelect={(selectedItem, index) => {
                console.log(selectedItem, index);
              }}
              ref={amountAlt2}
              disabled
              defaultValue={
                (intent === "Deposit" && CONFIG.supportedCurrencies[0]) ||
                (intent === "Withdraw" && CONFIG.supportedCurrencies[3]) ||
                CONFIG.supportedCurrencies[0]
              }
              renderButton={(selectedItem, isOpened) => {
                return (
                  <View style={globalStyles.dropdownButtonStyle}>
                    <Text style={globalStyles.dropdownButtonTxtStyle}>
                      {(selectedItem && selectedItem.title) ||
                        "Select currency"}
                    </Text>
                    <MaterialIcons
                      name={
                        isOpened ? "keyboard-arrow-up" : "keyboard-arrow-down"
                      }
                      color={"white"}
                      size={28}
                    />
                  </View>
                );
              }}
              renderItem={(item, index, isSelected) => {
                return (
                  <View
                    style={{
                      ...globalStyles.dropdownItemStyle,
                      ...(isSelected && { backgroundColor: "#D2D9DF" }),
                    }}
                  >
                    <Text style={globalStyles.dropdownItemTxtStyle}>
                      {item.title}
                    </Text>
                  </View>
                );
              }}
              showsVerticalScrollIndicator={false}
              dropdownStyle={globalStyles.dropdownMenuStyle}
            />
          </View>
        </View>

        <Text style={globalStyles.inputAmountFee}>Transaction cost: 0 KES</Text>
      </View>

      <CustomButton
        disabled={!canContinue}
        preferred={canContinue}
        text={"Next"}
        onPress={doContinue}
      />
    </View>
  );
};

export default InputAmount;
