import { View, Text, FlatList } from "react-native";
import React from "react";
import { MaterialIcons } from "@expo/vector-icons";
import FrequentTxItem from "./FrequentTxItem";
import { globalStyles } from "../styles/global";

const findFrequents = (arr, n) => {
  // find the most frequent element in array
  let result = [];

  for (let i = 0; i < n; i++) {
    let senderCount = 0;
    let recipientCount = 0;

    for (let j = 0; j < n; j++) {
      if (arr[i].incoming && arr[i].sender == arr[j].sender) senderCount++;
      if (arr[i].outgoing && arr[i].recipient == arr[j].recipient)
        recipientCount++;
    }

    let entity;
    if (arr[i].incoming)
      entity = {
        sender: arr[i].sender,
        senderName: `${arr[i].senderFirstName} ${arr[i].senderLastName}`,
        senderCount,
      };

    if (arr[i].outgoing)
      entity = {
        recipient: arr[i].recipient,
        recipientName: `${arr[i].recipientFirstName} ${arr[i].recipientLastName}`,
        recipientCount,
      };

    if (entity) result = [...new Set([...result, entity])];
  }

  result.sort(function (a, b) {
    if (a.recipientCount && b.recipientCount)
      return b.recipientCount - a.recipientCount;
  });
  result.sort(function (a, b) {
    if (a.senderCount && b.senderCount) return b.senderCount - a.senderCount;
  });

  return result;
};

const FrequentsCard = ({ history }) => {
  const [frequents, setFrequents] = React.useState([]);

  React.useEffect(() => {
    if (history) setFrequents(findFrequents(history, history.length));
  }, [history]);

  return (
    <View style={globalStyles.frequentsCard}>
      <View style={globalStyles.dashCardTitle}>
        <Text style={globalStyles.dashCardTitleText}>
          Frequent Transactions
        </Text>
        <View style={globalStyles.dashCardTitleIcon}>
          <MaterialIcons
            name="keyboard-arrow-right"
            size={26}
            color={"white"}
          />
        </View>
      </View>
      <View style={globalStyles.frequentsList}>
        {frequents?.length === 0 && (
          <Text style={globalStyles.emptyListLbl}>
            Your frequent transactions will show up here.
          </Text>
        )}
        <FlatList
          data={frequents}
          horizontal
          keyExtractor={(item) => Math.random()}
          renderItem={({ item }) => <FrequentTxItem item={item} />}
        />
      </View>
    </View>
  );
};

export default FrequentsCard;
