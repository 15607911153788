import axios from "axios";
import { ENDPOINTS } from "./urls.js";

function buildUrl(caller, data) {
  switch (caller) {
    case "getBal":
      return `${ENDPOINTS.getBal}/${data.phone}?${
        data.currency ? `currency=${data.currency}` : ``
      }${data.isMerchant ? `&isMerchant=${data.isMerchant}` : ``}`;

    case "getRates":
      return `${ENDPOINTS.getRates}/${data.currency}?${
        data.basePair ? `basePair=${data.basePair}` : ``
      }`;

    case "getTx":
      return `${ENDPOINTS.getTransactions}/find?${
        data.initiator ? `initiator=${data.initiator}&` : ``
      }${data.sender ? `sender=${data.sender}&` : ``}${
        data.recipient ? `recipient=${data.recipient}` : ``
      }`;

    case "getUser":
      return `${ENDPOINTS.getUser}/${data.phone}`;

    case "getMerchant":
      return `${ENDPOINTS.getMerchant}/${data.phone}`;

    case "verifyuserphone":
      return `${ENDPOINTS.verifyUserPhone}?phone=${data.phone}${
        data.otp ? `&otp=${data.otp}` : ``
      }${data.otpId ? `&otpId=${data.otpId}` : ``}`;

    case "verifymerchantphone":
      return `${ENDPOINTS.verifyMerchantPhone}?phone=${data.phone}${
        data.otp ? `&otp=${data.otp}` : ``
      }${data.otpId ? `&otpId=${data.otpId}` : ``}`;

    case "checkKycStatus":
      return `${ENDPOINTS.checkKycStatus}/${data?.phone}`;

    default:
      break;
  }
}

export function getBalance(data, callBack) {
  axios(buildUrl("getBal", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on get balance - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function getRates(data, callBack) {
  axios(buildUrl("getRates", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on get rate - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function getTransactions(data, callBack) {
  axios(buildUrl("getTx", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on get transactions - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function getUser(data, callBack) {
  axios(buildUrl("getUser", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on get user - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function getMerchant(data, callBack) {
  axios(buildUrl("getMerchant", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on get merchant - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function verifyUserPhone(data, callBack) {
  axios(buildUrl("verifyuserphone", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on verify user phone endpoint - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function verifyMerchantPhone(data, callBack) {
  axios(buildUrl("verifymerchantphone", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on verify merchant phone endpoint - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function userRegister(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.userRegister,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on register user endpoint - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function merchantRegister(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.merchantRegister,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on register merchant endpoint - `, err);
      if (err.response) return callBack(err.response?.data);
    });
}

export function userLogin(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.userLogin,
    data,
  })
    .then((res) => {
      // console.log("login res >> ", res.data);
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(`An error occured on user login endpoint - `, err);
      if (err) return callBack(err?.response?.data);
    });
}

export function merchantLogin(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.merchantLogin,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on merchant login endpoint - `,
        err?.response?.data
      );
      if (err) return callBack(err?.response?.data);
    });
}

export function submitKyc(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.submitKyc,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on submit kyc endpoint - `,
        err?.response?.data
      );
      if (err.response) return callBack(err.response?.data);
    });
}

export function checkKycStatus(data, callBack) {
  axios(buildUrl("checkKycStatus", data))
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on check kyc status endpoint - `,
        err?.response?.data
      );
      if (err.response) return callBack(err.response?.data);
    });
}

export function deposit(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.deposit,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on deposit endpoint - `,
        err?.response?.data
      );
      if (err.response) return callBack(err.response?.data);
    });
}

export function withdraw(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.withdraw,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on withdraw endpoint - `,
        err?.response?.data
      );
      if (err.response) return callBack(err.response?.data);
    });
}

export function sendTransfer(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.sendTransfer,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on transfer endpoint - `,
        err?.response?.data
      );
      if (err.response) return callBack(err.response?.data);
    });
}

export function sendBlockchain(data, callBack) {
  axios({
    method: "POST",
    url: ENDPOINTS.sendToBlockchain,
    data,
  })
    .then(async (res) => {
      return callBack(res.data);
    })
    .catch((err) => {
      console.log(
        `An error occured on send blockchain endpoint - `,
        err?.response?.data
      );
      if (err.response) return callBack(err.response?.data);
    });
}
