import { View, Text, TouchableOpacity } from "react-native";
import React from "react";
import { globalStyles } from "../styles/global";
import { MaterialIcons } from "@expo/vector-icons";

const FrequentTxItem = ({ item }) => {
  return (
    <TouchableOpacity style={globalStyles.frequentsTxItem}>
      <View style={globalStyles.frequentsTxItemIcon}>
        <MaterialIcons name="account-circle" size={50} color={"white"} />
      </View>
      <Text style={globalStyles.frequentsTxItemText}>
        {item?.senderName || item?.recipientName}
      </Text>
    </TouchableOpacity>
  );
};

export default FrequentTxItem;
