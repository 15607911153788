import React, { useEffect, useRef } from "react";
import { View, Text } from "react-native";
import { Animated, Easing } from "react-native";
import LottieView from "lottie-react-native";
import { globalColors, globalStyles } from "../styles/global";
import tw from "tailwind-react-native-classnames";
import CustomButton from "./CustomButton";

const AnimatedLottieView = Animated.createAnimatedComponent(LottieView);

const ProcessResult = ({ handleCloseProcess, result }) => {
  const animationProgress = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animationProgress.current, {
      toValue: 1,
      duration: 1200,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }, []);

  return (
    <View style={globalStyles.processResultView}>
      <View style={globalStyles.processResultAnimationView}>
        <AnimatedLottieView
          source={require("../assets/Success-Animation.json")}
          progress={animationProgress.current}
          style={tw`flex-1`}
          colorFilters={[
            {
              keypath: "check",
              color: globalColors().LUMINOUS_GREEN_HEX,
            },
            {
              keypath: "circle",
              color: globalColors().LUMINOUS_GREEN_HEX,
            },
          ]}
        />
      </View>
      <View style={globalStyles.processResultDetailsView}>
        <Text style={globalStyles.processResultDetailsText}>
          {result?.recipient?.txDesc}
        </Text>
      </View>

      <View style={globalStyles.processResultRefView}>
        <Text style={globalStyles.processResultRefText}>
          Ref: {result?.response?.tx?.txId}
        </Text>
      </View>

      <View style={globalStyles.processResultActionView}>
        <CustomButton
          preferred={true}
          text={"Close"}
          onPress={handleCloseProcess}
        />
      </View>
    </View>
  );
};

export default ProcessResult;
