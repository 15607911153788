import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import { globalStyles } from "../styles/global";

export default function CustomButton({
  disabled,
  preferred,
  textBtn,
  text,
  onPress,
}) {
  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <View
        style={
          preferred
            ? globalStyles.preferredBtn
            : textBtn
            ? globalStyles.textBtn
            : globalStyles.btn
        }
      >
        <Text
          style={textBtn ? globalStyles.textBtnOutline : globalStyles.btnText}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );
}
